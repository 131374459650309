import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography, styled } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { throttle } from "lodash"
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from "react-redux"
import InputSearch from "../../../pages/layout-components/InputSearch"
import { DialogTitle } from "../../../pages/layout-components/CustomDialog/dialogComponents"
import { BoxWaiter } from "./boxWaiter"
import AuthService from "../../../service/AuthService";
import { useSnackbar } from "notistack";
import { extractErrorMessage } from "../../../util/http/HttpUtil";

const PREFIX = 'WaiterSearch'

const classes = {
    paper: `${PREFIX}-paper`,
    boxViewWaiters: `${PREFIX}-boxViewWaiters`
}

const Root = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },

    [`& .${classes.boxViewWaiters}`]:{
        maxHeight: 300,
    },

    [`& .${classes.dialogPadding}`]: {
        padding: "10px 10px"
    }
}))

export const SelectWaiterBoard = ({ onConfirm, open, setOpen, defaultWaiter, setSelectedWaiter, selectedWaiter, size }) => {
    const btnConfirmRef = useRef(null)
    //const [selectedWaiter, setSelectedWaiter] = useState(null)
    const [blockButton, setBlockButton] = useState(false)
    const usuarios = useSelector(state => state.dialogSelectWaiter.usuario) //|| []
    const [options, setOptions] = useState(usuarios || [])
    const productsRef = useRef([])
    const [loadingUsuarios, setLoadingUsuarios] = useState(true)

    const service = new AuthService()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!usuarios && open) {
            service.get(`/api-v2/usuario/listUsuarios`)
                .then(users => {
                    dispatch({ type: 'TOOGLE_SELECT_WAITER', dialogSelectWaiter: { usuarios: users, open: false } })
                }).catch(err => {
                    extractErrorMessage(err, 'Erro ao buscar os usuários do sistema')
                        .then(msg => {
                            enqueueSnackbar(`Erro ao buscar os usuarios - ${msg}`, { variant: "error" })
                        })
                }).finally(() => {
                    setLoadingUsuarios(false)
                })
        }
    }, [open])

    useEffect(() => {
        setOptions(usuarios)
    }, [usuarios]);


    useEffect(() => {
        if (open) {
            setOptions(usuarios)
            setSelectedWaiter(defaultWaiter)
        }
    }, [open]);

    useEffect(() => {
        if (btnConfirmRef.current) {
            btnConfirmRef.current.focus()
        }
    }, [selectedWaiter]);

    const hadleSelectWaiter = (Waiter) => {      
        setSelectedWaiter(Waiter)      
    }

      //evento de tecla na pesquisa
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {//foca no primeiro item dos itens filtrados
            event.preventDefault()
            handleFocusOnFirtsProduct()
        }
    }

    //seta o foco no primeiro produto
    const handleFocusOnFirtsProduct = () => {
        if (productsRef.current) {
            if (productsRef.current[0]) {
                productsRef.current[0].focus()
            }
        }
    };

    const handleConfirm = () => {
        setOpen(false)
        if (onConfirm) {
            onConfirm(selectedWaiter)
        }
    }

    const handleClickCancel = () => {
        setOpen(false)
    }
    const handleSearch = (value, usuarios) => {

        if (value === '') {
            setOptions(usuarios);
            return undefined;
        }

        const newUser = (usuarios || []).filter(user => {
            return user.nome.toUpperCase().indexOf(value.toUpperCase()) >= 0 
        })

        console.log("busca", newUser)

        if(!!!newUser.length)
            setBlockButton(true)
        else
            setBlockButton(false)
    
        setOptions(newUser)
    }

    const handleSearchThrottle = React.useMemo(
        () => throttle(handleSearch, 200),
        [],
    );

    return(
        <Root maxWidth='md'
            fullWidth
            open={open}
            scroll='paper'
            fullScreen={false}
            onClose={handleClickCancel}
        >
            <DialogTitle onClose={handleClickCancel} sx={{display: 'grid'}}>
                <Typography sx={{fontSize: '14px'}} gutterBottom> Selecione o Usuário </Typography>
                <Box py={1} px={1}>
                    <InputSearch                                    
                        fullWidth
                        size='small'
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        placeholder='procurar pelo nome'
                        onFocus={event => event.target.select()}
                        label="procurar produto pelo nome"
                        inputProps={{ 'aria-label': 'procurar pelo nome' }}
                        onChange={event => handleSearchThrottle(event.target.value, usuarios)}
                    />
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogPadding}>
                <Grid container className={classes.dialogPaper} spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            display="flex"
                            flexDirection='column'
                            height='100%'
                        >
                            <Box display='grid' className={classes.boxViewWaiters} gridTemplateColumns='repeat( auto-fit, minmax(150px, 1fr) )' gap={2}>
                                {loadingUsuarios && ("Buscando...")}

                                {options && options.map(usuario => (
                                    <BoxWaiter 
                                        key={usuario.id} 
                                        usuario={usuario}
                                        Paper={Paper}
                                        selectedWaiter={selectedWaiter}
                                        hadleSelectWaiter={hadleSelectWaiter}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

                <DialogActions>
                    <Button ref={btnConfirmRef}
                        disabled={!selectedWaiter || selectedWaiter.idPessoa === null || blockButton}
                        variant="contained"
                        onClick={handleConfirm}
                        endIcon={<DoneIcon />}
                        color="primary">Confirmar</Button>
                </DialogActions>
        </Root>
    )
}