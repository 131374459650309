import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    IconButton,
    Collapse,
    Tooltip,
    ListItemButton,
    Grid,
    Badge,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MotorcycleIcon from '@mui/icons-material/TwoWheeler';
import StorefrontIcon from '@mui/icons-material/Storefront';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getLevarTrocoPara, getValorEmDinheiro, isDeliveryOrder, isTakeout } from "../../../util/order/orderUtil"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { currencyFormat, dateFormat, formatPhoneBR, quantityFormat } from "../../../util/formatter"
import AuthService from '../../../service/AuthService';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import SelectDeliveryman from '../NewOrder/SelectDeliveryman';
import { useDispatch, useSelector } from 'react-redux'
import { createCardFromPedido } from '../../../util/board/BoardUtil';
import EventIcon from '@mui/icons-material/Event';
import { AGENDADO_COLOR, LOGISTICS_SENT, TAKEOUT_COLOR, CONSUMIR_LOCAL_COLOR } from '../../../theme/palette';
import Check from '@mui/icons-material/Check';
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';
import clsx from 'clsx';
import PersonIcon from '@mui/icons-material/Person';
import { convertOrderAddressToCustomerAddress, formatFullAddress, formatFullAddressCustomer } from '../../../util/customer/helper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ItemInfo from '../../../util/item/itemInfo';
import StoreIcon from '@mui/icons-material/Store';
import PinDropIcon from '@mui/icons-material/PinDrop';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { tefFetchConsultaVenda } from '../../../util/order/tefUtil';
import { useSnackbar } from 'notistack';
import { usePdvContext } from '../../../component/generics/PdvProvider';
import { useTheme } from '@emotion/react';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const PREFIX = 'detailOrderElements';

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    useRowStyles: `${PREFIX}-useRowStyles`,
    listItem: `${PREFIX}-listItem`,
    checkCompleted: `${PREFIX}-checkCompleted`,
    notCompleted: `${PREFIX}-notCompleted`,
    motorcycleCompleted: `${PREFIX}-motorcycleCompleted`,
    homeCompleted: `${PREFIX}-homeCompleted`,
}

const StyledList = styled(List)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.listItem}`]: {
        paddingTop: '0px',
        paddingBottom: '0px',
    }
}))

const StyledTableRow = styled(TableRow)(() => ({
    [`&.${classes.useRowStyles}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
}))

const StyledIconWrapper = styled('div')(({ theme }) => ({
    [`& .${classes.checkCompleted}`]: {
        color: '#4caf50',
    },

    [`& .${classes.notCompleted}`]: {
        color: theme.palette.secondary.main
    },

    [`& .${classes.motorcycleCompleted}`]: {
        color: '#795548',
    },

    [`& .${classes.homeCompleted}`]: {
        color: '#bdbdbd',
    },
}));

export function Address({ card }) {
    const { confirmDialog } = usePdvContext()
    const dispatch = useDispatch();

    const addressCustomer = formatFullAddress(convertOrderAddressToCustomerAddress(card.data?.iFoodOrder?.deliveryAddress))
    const entregador = card.data.pessoaEntregador;

    const [openDialogEntregador, setOpenDialogEntregador] = React.useState(false);

    const onConfirmSelectDialog = (pedidoImportacao) => {
        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao } })

        let updatedCard = createCardFromPedido(pedidoImportacao)
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
    }

    const openAddress = () => {
        let service = new AuthService()
        let profile = service.getProfile()

        if (profile) {
            const empresa = profile.unidade

            if (!empresa) {
                confirmDialog({
                    confirmLabel: 'Ok',
                    showCancelButton: false,
                    content: 'Nenhuma empresa encontrada. Por favor, verifique.',
                }).catch(() => { })

                return
            }

            const pessoaUnidade = empresa.pessoa

            if (!pessoaUnidade) {
                confirmDialog({
                    confirmLabel: 'Ok',
                    showCancelButton: false,
                    content: 'Nenhuma pesssoa associada no cadastro da empresa. Por favor, verifique.',
                }).catch(() => { })

                return
            }

            let profileAddress = formatFullAddressCustomer(pessoaUnidade)?.address
            window.open(`https://site.parseint.com.br/Mapa/index.php?txtEnderecoPartida=${encodeURIComponent(profileAddress)}&txtEnderecoChegada=${encodeURIComponent(addressCustomer?.address)}`)
        }
    }

    const handleOpenDialogEntregador = () => {
        setOpenDialogEntregador(true)
    }

    return (
        <div>
            <StyledList dense subheader='Endereço para entrega'>
                <ListItem dense >
                    <ListItemIcon ><LocationOnIcon /></ListItemIcon>
                    <ListItemText
                        primary={addressCustomer?.address}
                        secondary={addressCustomer?.complement} />

                    <ListItemSecondaryAction>
                        <Tooltip title='Ver a rota até o endereço de entrega'>
                            <IconButton edge="end" onClick={openAddress} size="large">
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItemButton dense onClick={handleOpenDialogEntregador}>
                    <ListItemIcon ><SportsMotorsportsIcon /></ListItemIcon>
                    <ListItemText primary={entregador ? entregador.nome : " -- sem entregador --"}
                        secondary={entregador ? 'Entregador' : "(clique para editar)"}>
                    </ListItemText>
                </ListItemButton>
            </StyledList>

            <div>
                <SelectDeliveryman
                    handleClose={() => setOpenDialogEntregador(false)}
                    open={openDialogEntregador}
                    pedidoIntegracao={card.data}
                    onConfirm={onConfirmSelectDialog}
                />
            </div>
        </div>
    );
}

export function Payments({ payments }) {
    const dispatch = useDispatch()
    const pays = payments || []
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    function handleConsultaVendaTef(tefInfo) {
        const service = new AuthService()
        const { intencaoVendaId } = tefInfo

        setLoading(true)
        service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=TEF').then(async integracoesTef => {
            if (!integracoesTef?.length) {
                return
            }

            const dados = JSON.parse(integracoesTef[0].dados)
            await tefFetchConsultaVenda(dados.TEF_ON_KEY, { intencaoVendaId }).then(async data => {
                dispatch({ type: 'TOOGLE_TEF_SALE_INFO_DIALOG', dialogTefSaleInfo: { open: true, sale: data } })
            }).catch((err) => {
                console.log('ERRO_CONSULTA_INTENCAO_VENDA_TEF', err)
                enqueueSnackbar('Erro ao buscar informações da intenção de venda do TEF', { variant: 'error' })
            })
        }).catch((err) => {
            console.log('ERRO_CONSULTA_INTEGRACOES_ATIVAS_TEF', err)
            enqueueSnackbar('Erro ao buscar informações da integração do TEF', { variant: 'error' })
        }).finally(() => setLoading(false))
    }

    return (
        <List dense subheader='Formas de pagamento'>
            {pays.length > 0 ? (
                pays.map((payment, index) => (
                    <ListItem dense key={index}>
                        <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                        <ListItemText primary={`${payment.name || ''}  ( ${payment.code || ''} )`}
                            secondary={payment.prepaid === true ?
                                (
                                    <Typography color="primary">pago online</Typography>
                                ) : ''
                            }
                        >
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {payment.tefInfo ? (
                                <Tooltip title="Consultar venda">
                                    <IconButton
                                        sx={{ mr: 1 }}
                                        disabled={loading}
                                        onClick={() => handleConsultaVendaTef(payment.tefInfo)}>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {currencyFormat(payment.value)}
                        </ListItemSecondaryAction>
                    </ListItem>
                )))
                : (
                    <Typography color="error">Nenhum pagamento informado</Typography>
                )

            }
        </List>
    )
}

export function Observations({ order }) {
    const taxPayerIdentificationNumber = order?.customer?.taxPayerIdentificationNumber;
    const cashValue = getValorEmDinheiro(order)
    const changeFor = getLevarTrocoPara(order)
    let changeForOrder = 0

    if (changeFor > 0.0) {
        changeForOrder = changeFor - cashValue
    }

    return (
        <div>
            {taxPayerIdentificationNumber && <Alert severity="warning">Incluir CPF na nota {taxPayerIdentificationNumber}</Alert>}
            {cashValue > 0 && (<Alert variant="outlined" severity="warning">Valor a receber em dinheiro {currencyFormat(cashValue)}
                {changeFor > 0 &&
                    <div> Troco para {currencyFormat(changeFor)}
                        <br />  Valor para levar de troco {currencyFormat(changeForOrder)}
                    </div>
                }
            </Alert>)}

            {order.observations && (
                <Alert severity="info">
                    <AlertTitle>Observações</AlertTitle>
                    {order.observations}
                </Alert>
            )}
        </div>
    )
}

export function Itens({ order }) {

    const itens = order.items || [];
    return (
        <StyledList dense subheader='Itens'>
            <TableContainer className={classes.scrollBar}>
                <Table size="small" aria-label="Itens do pedido">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell /> */}
                            <TableCell style={{ padding: "0px" }}>Descrição</TableCell>
                            <TableCell align="right">Qtde</TableCell>
                            <TableCell align="right">Un</TableCell>
                            <TableCell align="right">Total</TableCell>
                            <TableCell padding="checkbox" >Info</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {itens.map((item, index) => (
                            <ItemRow item={item} key={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledList>
    )
}

export function ItemRow({ item, opened }) {
    const [open, setOpen] = React.useState(opened ? opened : false);

    const [openInfoItem, setOpenInfoItem] = React.useState(false)


    const handleClickOpenInfo = () => {
        setOpenInfoItem(true)
    }

    return (
        <React.Fragment>
            <StyledTableRow className={classes.useRowStyles}>

                <TableCell component="th" scope="row">
                    {item.name}

                    {item.observations &&
                        <ListItemText
                            secondary={'* ' + item.observations} />
                    }
                </TableCell>

                <TableCell align="right">
                    {quantityFormat(item.quantity)}
                </TableCell>

                <TableCell align="right">
                    {currencyFormat(item.price, { simbol: '' })}
                </TableCell>

                <TableCell align="right">
                    {currencyFormat(item.totalPrice, { simbol: '' })}
                </TableCell>

                <TableCell style={{ padding: "0px" }} align='center'>

                    <Tooltip title="Informações do item" aria-label="add">
                        <IconButton size="small" onClick={handleClickOpenInfo} >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>

                {item.subItems && item.subItems.length > 0 &&
                    <TableCell style={{ padding: "0px" }}>

                        <IconButton aria-label="Subitens"
                            disabled={!item.subItems || item.subItems.length === 0}
                            size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                    </TableCell>
                }

            </StyledTableRow>

            {item.subItems && item.subItems.length > 0 &&
                <TableRow >
                    <TableCell colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>

                            <Table size="small" aria-label="Subitens">
                                <TableBody>
                                    {(item.subItems || []).map((subitem, index) => (
                                        <StyledTableRow key={index} className={classes.useRowStyles} >
                                            <TableCell component="th">
                                                {subitem.name}

                                                {subitem.observations &&
                                                    <ListItemText
                                                        secondary={'* ' + subitem.observations} />
                                                }
                                            </TableCell>

                                            <TableCell align="right" >
                                                {quantityFormat(subitem.quantity)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {currencyFormat(subitem.totalPrice, { simbol: '' })}
                                            </TableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                </TableRow>
            }

            <ItemInfo
                open={openInfoItem}
                setOpen={setOpenInfoItem}
                item={item} />

        </React.Fragment >
    )
}

export function StatusOrder({ order }) {

    const canceled = !!order.dataCancelamento;
    const isDeleted = !order.ativo;

    const takeOut = isTakeout(order);
    // eslint-disable-next-line
    const mesa = order?.iFoodOrder?.deliveryMethod?.mode == 'Mesa';
    const balcao = order?.iFoodOrder?.deliveryMethod?.mode === 'BALCAO';
    const consumirLocal = order?.iFoodOrder?.deliveryMethod?.mode === 'INDOOR';

    const scheduled = order?.pedidoAgendado === 'AGENDADO';
    const isDelivery = isDeliveryOrder(order);

    function StatusOrderIcon(props) {
        const { indexIcon, } = props
        const completed = getCompleted(indexIcon)

        const icons = {
            0: <Check className={clsx({ [classes.checkCompleted]: completed, [classes.notCompleted]: !completed })} />,
            1: <MotorcycleIcon className={clsx({ [classes.motorcycleCompleted]: completed, [classes.notCompleted]: !completed })} />,
            2: <HomeIcon className={clsx({ [classes.homeCompleted]: completed, [classes.notCompleted]: !completed })} />,
            3: <CancelIcon className={clsx({ [classes.homeCompleted]: completed, [classes.notCompleted]: !completed })} />,
        };

        function getCompleted(stepIndex) {

            let active = false

            if (stepIndex === 0) {
                active = order.dataConfirmacao && order.dataConfirmacao !== null
            } else if (stepIndex === 1) {
                active = order.dataEnvio && order.dataEnvio !== null
            } else if (stepIndex === 2) {
                active = order.dataEntrega && order.dataEntrega !== null
            } else if (stepIndex === 3) {
                active = order.dataCancelamento && order.dataCancelamento !== null
            }

            return active
        }

        return (
            <StyledIconWrapper>
                {icons[String(indexIcon)]}
            </StyledIconWrapper>
        );
    }

    return (
        <List subheader='Informações do pedido' dense>
            {order?.iFoodOrder?.merchant && (
                <ListItem dense>
                    <ListItemIcon>
                        <StoreIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary={`Loja ${order.iFoodOrder.merchant.name}`} />
                </ListItem>
            )}

            {isDelivery && (

                <ListItem dense
                    secondaryAction={
                        order.dataConhecimento && (
                            <Tooltip arrow={true} title={`Pedido encontrado ${dateFormat(order.dataConhecimento)}`} >
                                <Badge color="info" badgeContent={1} >
                                    <NotificationsNoneOutlinedIcon color='secondary' />
                                </Badge>
                            </Tooltip>
                        )
                    } >
                    <ListItemIcon>
                        {StatusOrderIcon({ indexIcon: 0, })}
                    </ListItemIcon>
                    <ListItemText id="statusOrderChecked" primary='Confirmado' secondary={order.dataConfirmacao ? `${dateFormat(order.dataConfirmacao)}` : 'Não confirmado'} />
                </ListItem>

            )}

            {!isDelivery && (
                <React.Fragment>
                    <ListItem dense>
                        <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Criado em" secondary={`${dateFormat(order.dataPedido)}`} />
                    </ListItem>

                </React.Fragment>
            )}

            {isDeleted && !canceled ? (
                <Alert severity="error">
                    <AlertTitle>
                        {`Pedido excluído`}
                    </AlertTitle>
                    <Typography variant='caption' gutterBottom>
                        {order.justificativaExclusao}
                    </Typography>
                </Alert>
            ) : null}

            {canceled ?
                <Alert severity="error">
                    <AlertTitle>
                        {`Cancelado ${dateFormat(order.dataCancelamento)}`}
                    </AlertTitle>
                    <Typography variant='caption' gutterBottom>
                        {order.motivoCancelamento}
                    </Typography>
                </Alert>
                :
                <div>
                    {takeOut && isDelivery && (
                        <ListItem dense>
                            <ListItemIcon>
                                <StorefrontIcon style={{ color: TAKEOUT_COLOR }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Para retirar"
                            />
                        </ListItem>
                    )}

                    {mesa && isDelivery && (
                        <ListItem dense>
                            <ListItemIcon>
                                <StorefrontIcon style={{ color: TAKEOUT_COLOR }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={order.iFoodOrder?.deliveryMethod?.mode}
                            />
                        </ListItem>
                    )}

                    {balcao && isDelivery && (
                        <ListItem dense>
                            <ListItemIcon>
                                <StorefrontIcon style={{ color: TAKEOUT_COLOR }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Pedido de balcão"
                            />
                        </ListItem>
                    )}

                    {consumirLocal && isDelivery && (
                        <ListItem dense>
                            <ListItemIcon>
                                <RestaurantIcon style={{ color: CONSUMIR_LOCAL_COLOR }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Consumo no local"
                            />
                        </ListItem>
                    )}

                    {scheduled && isDelivery &&
                        (<ListItem dense>
                            <ListItemIcon>
                                <EventIcon style={{ color: AGENDADO_COLOR }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Pedido agendado'
                                secondary={dateFormat(order?.dataPedido)} />
                        </ListItem>)
                    }

                    {!takeOut && isDelivery && !balcao && !mesa && !consumirLocal &&
                        <div>
                            <ListItem dense>
                                <ListItemIcon>
                                    {StatusOrderIcon({ indexIcon: 1, })}
                                </ListItemIcon>
                                <ListItemText id="statusOrderDelivering" primary={order.dataEnvio && 'Enviado'} secondary={order.dataEnvio ? ` ${dateFormat(order.dataEnvio)}` : 'Não enviado'} />
                            </ListItem>

                            <ListItem dense>
                                <ListItemIcon>
                                    {StatusOrderIcon({ indexIcon: 2, })}
                                </ListItemIcon>
                                <ListItemText id="statusOrderDelivered" primary={order.dataEntrega && 'Entregue'} secondary={order.dataEntrega ? dateFormat(order.dataEntrega) : 'Não entregue'} />
                            </ListItem>
                        </div>
                    }

                    {"ENVIADO" === order.statusLogistica && (
                        <ListItem dense>
                            <ListItemIcon>
                                <PinDropIcon fontSize="small" style={{ color: LOGISTICS_SENT }} />
                            </ListItemIcon>
                            <ListItemText primary="Enviado p/ logística" />
                        </ListItem>
                    )}
                </div>
            }
        </List >
    )
}

export function CustomerOrderInfoByPlataform({ pedidoIntegracao }) {
    const tipoImportacao = pedidoIntegracao.tipoImportacao
    const customerPhone = pedidoIntegracao?.iFoodOrder?.customer?.phone;

    return (
        "DM" === tipoImportacao && !customerPhone && (
            <Alert severity="warning">Para mostrar o telefone do cliente será necessário ir em <strong>Opções - Atualizar pedido</strong></Alert>
        )
    )
}

export function CustomerOrder({ customer, complementoIdentificador }) {
    const theme = useTheme()
    const dispatch = useDispatch();
    const primary = customer?.name;
    const secondary = `${customer?.phone ? formatPhoneBR(customer?.phone) : ''} ${customer?.email ? ' | ' + customer?.email : ''}`;
    const order = useSelector(state => state.detailOrder.card.data)
    const [respNf, setRespNf] = useState()
    const [isAproved, setIsAproved] = useState(false)

    useEffect(() => {
        if (respNf) {
            setIsAproved(verifyIfAproved(respNf))
        } else {
            setIsAproved(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respNf])

    useEffect(() => {
        if (order) {
            setRespNf({ ...order.pedido })
        }
        // eslint-disable-next-line
    }, [order])

    const verifyIfAproved = (respNF) => {
        if (respNF && (respNF.status || respNF.status === 0)) {
            dispatch({ type: 'TOOGLE_DIALOG_INVOICE', detailDialogInvoice: { open: false, order: order } })
            return [100, 101, 102, 103, 105, 135, 150, 0].includes(parseInt(respNF.status));
        }
        return false;
    }
    
    return (
        <>
            <List subheader='Cliente' dense>
                <ListItem dense>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={primary}
                        secondary={secondary} />
                </ListItem>

                <ListItem dense>

                </ListItem>

                {complementoIdentificador && (
                    <ListItem dense>
                        <ListItemIcon>
                            <EmojiPeopleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Complemento identificador"}
                            secondary={complementoIdentificador} />
                    </ListItem>
                )}

            </List>
            {respNf && respNf?.statusDescricao &&
                <List subheader='Nota Fiscal' dense >
                    <Grid item xs={12}>
                        <Alert variant="outlined"                           
                          severity={isAproved ? "success" : "warning"}>
                            <AlertTitle>{respNf?.statusDescricao}</AlertTitle>
                            {!isAproved ? respNf?.msgStatus : null}
                        </Alert>
                    </Grid>
                </List>
            }            
        </>
    )
}

