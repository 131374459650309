import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Button, Menu, MenuItem, ListItemText, ListItemAvatar, Avatar, ListItemIcon, LinearProgress, ButtonGroup, Divider } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import InputIcon from '@mui/icons-material/Input';
import {
    SITUACOES, changeSituacaoPedidoOnServer, sendPedidoToPrint, createCardFromPedido, getTipoImportacaoBySigla,
    getSituacaoBySigla, showMsgErrorOuSuccessOnReturPrint
} from '../../../util/board/BoardUtil'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from "../../../util/http/HttpUtil"
import { dateFormat } from "../../../util/formatter"
import UndoIcon from '@mui/icons-material/Undo';
import { getSettings } from "../../settings/settings"
import AuthService from '../../../service/AuthService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isDeliveryOrder, isOrderPayed, onConfirmOrder } from '../../../util/order/orderUtil';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CancelIcon from '@mui/icons-material/Cancel';
import FiscalDocument from '../fiscalDocument';
import { ifAnyGranted, ifAnyGrantedUnidade } from '../../../util/auth/authUtil';
import RefreshIcon from '@mui/icons-material/Refresh';
import CompareIcon from '@mui/icons-material/Compare';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DialogLogistic from '../../../component/dialogLogistic';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getParamByKey } from '../../../component/parameter';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { getGenericCodeByKey } from '../../../component/genericCode';
import SendIcon from '@mui/icons-material/Send';
import FlipIcon from '@mui/icons-material/Flip';
import DescriptionIcon from '@mui/icons-material/Description';
import OrderInfo from '../../../util/order/orderInfo';
import ReactGA from "react-ga4";
import { usePdvContext } from '../../../component/generics/PdvProvider';
import { useOrder } from '../NewOrder/Order';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { generateOrderSummary, copyToClipboard } from '../../../util/order/orderUtil';
import { useFunction } from '../fiscalDocument/functions';

const PREFIX = 'ActionButtons'

const classes = {
    deleteBtn: `${PREFIX}-deleteBtn`,
    smallAvatar: `${PREFIX}-smallAvatar`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.smallAvatar}`]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },

    [`& .${classes.deleteBtn}`]: {
        color: theme.palette.error.main
    }
}))

const StyledButton = styled(Button)(({ theme }) => ({
    [`&.${classes.deleteBtn}`]: {
        color: theme.palette.error.main
    }
}))

export default function ActionButtons({ card }) {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [undoImportRunning, setUndoImportRunning] = React.useState(false)
    const order = card.data;
    const tipoImportacao = getTipoImportacaoBySigla(card.data.tipoImportacao)
    const orderCanceled = card.data.situacao === 'CANCELADO'
    const generalParameters = useSelector(state => state.generalParameters)
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com'

    const canEditOrder = !tipoImportacao?.integracao && !order?.dataImportacao
    const canImportOrder = tipoImportacao?.integracao || (isOrderPayed(order?.iFoodOrder))
    const isDelivery = isDeliveryOrder(order);
    const statusMoneyBox = useSelector(state => state.statusMoneyBox.status) // recebe o status global do caixa
    const [anchorEl, setAnchorEl] = React.useState(null);
    const locaisDeImpressao = useSelector(state => state.printerLocations) || []
    const { cancelOrder } = useOrder()

    const handleClickPrintSplit = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePrintSplit = () => {
        setAnchorEl(null);
    };

    const openDetailOrder = (event) => {
        if (!canImportOrder) {
            enqueueSnackbar('Realize o pagamento do pedido antes de importar.', { variant: "error" });
            return
        }

        event.stopPropagation()

        const copyCard = JSON.parse(JSON.stringify(card))

        dispatch({ type: 'TOOGLE_IMPORT_ORDER_DIALOG', importOrder: { open: true, card: copyCard } })
    }

    const handleEditOrder = () => {
        //fecha o dialog atual
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: false } })


        const orderCopy = JSON.parse(JSON.stringify(order))

        //abre de edição de proudutos
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: orderCopy, initActiveStep: 1 } })
    }

    const handleOpenPaymentDialog = () => {

        if (statusMoneyBox === 'CLOSE') { //verifica o status do caixa e confere se ele não está fechado
            //utilizando a variável statusMoneyBox
            enqueueSnackbar('O caixa está fechado, é necessário abrir o caixa para realizar pagamentos.', { variant: "error" });
        } else {
            //Envia para o analytics a informação do clique no botão de pagamentos do pedido
            ReactGA.event({
                category: 'Button',
                action: 'Click pagamentos',
                label: 'Botão pagamentos do pedido'
            });

            //fecha o dialog atual
            dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: false } })

            const orderCopy = JSON.parse(JSON.stringify(order))

            //abre de edição de proudutos
            dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: orderCopy } })
        }

    }

    const undoImportEvent = (card) => {
        if (ifAnyGranted('ROLE_NAO_PERMITE_DESFAZER_IMPORTACAO_PEDIDO')) {
            enqueueSnackbar('Seu usuário não tem permissão para desfazer importações', { variant: 'error' })
            return
        }

        let id = card.id
        setUndoImportRunning(true)
        let service = new AuthService();
        service.post("/api-v2/pedidoIntegracao/desfazerIntegracao/" + id)
            .then(pedidoIntegracao => {

                setUndoImportRunning(false)
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })

            }).catch(err => {
                setUndoImportRunning(false)
                console.error(err)

                extractErrorMessage(err, "Não foi possível desfazer a integração")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })

            })
    }

    const handlePrintOrder = (event, localImpressao) => {
        event.stopPropagation()
        handleClosePrintSplit()

        let printApplication = getSettings().printApplication

        if (printApplication === "APP") {
            card.data.origemImpressao = "USUARIO"
            sendPedidoToPrint(card.data, localImpressao)
                .then(resp => {
                    console.log('Pedido enviado para impressao. Retorno: ', resp)
                    showMsgErrorOuSuccessOnReturPrint(resp, enqueueSnackbar)
                    //enqueueSnackbar('Pedido enviado com sucesso para impressao');
                }).catch(err => {
                    console.error('Erro ao enviar pedido para impressao. Retorno: ', err)
                    enqueueSnackbar('Erro ao enviar pedido para impressao.', { variant: "error" });
                })
        } else {

            window.open(`/impressao-online.html?ids=${card.data.id}`, '_blank', 'noreferrer')

            // let service = new AuthService()
            // service.get(`/api-v2/impressaoAPI/carregarConteudoImpressao/PedidoIntegracao/${card.data.id}`)
            //     .then(pedidoPrintResp => {
            //         const conteudoImpressaoEscapado = encodeURIComponent(JSON.stringify(pedidoPrintResp));
            //         window.open(`/impressao-online.html?content=${conteudoImpressaoEscapado}`, '_blank', 'noreferrer')
            //     }).catch(err => {
            //         enqueueSnackbar(`Não foi possível imprimir pedido. Erro: ${err}`, { variant: 'error' })
            //     })
        }
    }

    const handleSendOrResetNotificationArea = (order, reset) => {

        if ("NOTIFICADO" === order.statusNotificacao && !!reset) {
            handleResetNotificationArea(order)
        } else {
            handleNotifyItemsManually(order)
        }

        handleClosePrintSplit()
    }


    const handleResetNotificationArea = order => {
        if ("NOTIFICADO" === order.statusNotificacao) {

            let orderCopy = Object.assign({}, order)
            orderCopy.statusNotificacao = "NAO_NOTIFICADO"

            const profile = new AuthService().getProfile();
            const data = new Date();
            orderCopy.logs = !!orderCopy.logs ? orderCopy.logs + " \n" : ""
            orderCopy.logs = orderCopy.logs + "Pedido: " + orderCopy.id + " Renotificado todos os itens manualmente pelo usuário: " + profile.nome + ` em ${dateFormat(data)}`

            let service = new AuthService()
            service.post('/api-v2/pedidoIntegracao', orderCopy)
                .then((respOrder) => {
                    enqueueSnackbar('Pedido notificado novamente', { variant: 'success' })

                    dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder } })

                    let updatedCard = createCardFromPedido(respOrder)
                    dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })

                }).catch(err => {

                    extractErrorMessage(err, "Erro ao alterar status de notificação do pedido")
                        .then(msg => {
                            enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                        })

                })

        }

    }

    const cancelarPedidoKDS = (pedido, justificativa = null) => {
        const LOCAL_NOTIFICACAO_ITEM = getParamByKey(generalParameters, 'LOCAL_NOTIFICACAO_ITEM')?.valorString

        if (['KDS', 'AMBOS'].includes(LOCAL_NOTIFICACAO_ITEM)) {
            const serviceImpressao = new AuthService(URL_SERVIDOR_IMPRESSAO)
            const profile = serviceImpressao.getProfile()

            var order = {
                id: pedido.id,
                motivoCancelamento: justificativa ?? pedido.motivoCancelamento
            }

            const options = {
                method: 'POST',
                headers: {
                    "unidadeuuid": profile.unidade.uuid,
                    "Accept": "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(order)
            }

            serviceImpressao.request('/api/kds/cancelarItemsByPedido', options).then(response => {
                response.json().then(
                    retorno => {
                        console.log("Itens cancelados no kds", retorno)
                    }
                )
            }).catch(err => {
                console.error("Erro ao tentar cancelar os itens no kds", err)
            });
        }

    }

    const handleCancelOrder = async (card) => {
        const order = card.data

        await cancelOrder(order, null, true)
    }

    const handleNotifyItemsManually = (order) => {
        let orderCopy = Object.assign({}, order)
        let count = 0

        if (orderCopy.iFoodOrder?.items?.length > 0) {

            orderCopy.iFoodOrder.items.map((item) => {
                if (item.status === "NOTIFICADO" || item.status === "PENDENTE") {
                    item.status = "PENDENTE"
                    count++
                }
                return null;
            });

        }
        const profile = new AuthService().getProfile();
        const data = new Date();
        orderCopy.logs = !!orderCopy.logs ? orderCopy.logs + " \n" : ""
        orderCopy.logs = orderCopy.logs + "Pedido: " + orderCopy.id + " Renotificado todos os itens manualmente pelo usuário: " + profile.nome + ` em ${dateFormat(data)}`

        let service = new AuthService()
        service.post('/api-v2/pedidoIntegracao', orderCopy)
            .then((respOrder) => {
                enqueueSnackbar(count + ' Itens do pedido enviados para notificação', { variant: 'success' })

                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder } })

                let updatedCard = createCardFromPedido(respOrder)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })

            }).catch(err => {

                extractErrorMessage(err, "Erro ao alterar status de notificação do pedido")
                    .then(msg => {
                        enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                    })

            })
    }

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item xs={12}>
                <Button
                    color="grey"
                    variant="contained"
                    fullWidth endIcon={<EditIcon />}
                    onClick={handleEditOrder}
                    disabled={!canEditOrder || orderCanceled}>Editar</Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    color="success"
                    variant="contained"
                    fullWidth endIcon={<AttachMoneyIcon />}
                    onClick={handleOpenPaymentDialog}
                    disabled={!canEditOrder || orderCanceled}>Realizar pgto</Button>
            </Grid>
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="grey" style={{ display: 'flex' }}>
                    <Button variant="contained"
                        color="grey"
                        fullWidth
                        endIcon={<PrintIcon />}
                        onClick={handlePrintOrder}
                        style={{ flexGrow: '1' }} >Imprimir
                    </Button>
                    <Button
                        size="small"
                        color="grey"
                        aria-haspopup="menu"
                        onClick={handleClickPrintSplit} >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClosePrintSplit}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem dense disabled>
                        Imprimir resumo completo em
                    </MenuItem>
                    {locaisDeImpressao.map(local => (
                        <MenuItem
                            key={local._id} value={local.chave}
                            onClick={(event) => handlePrintOrder(event, local.chave)}>

                            <ListItemIcon>
                                <PrintIcon fontSize="small" />
                            </ListItemIcon>

                            <ListItemText primary={`Imprimir em ${local.descricao}`} />

                        </MenuItem>
                    ))}


                    <Divider />

                    <MenuItem
                        onClick={(event) => handleSendOrResetNotificationArea(card.data, true)}>
                        <ListItemIcon>
                            <SendIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={(order.statusNotificacao === 'NOTIFICADO' || order.tipoImportacao === "SO") ? "Enviar para notificação novamente" : "Enviar para áreas de notificação"}
                            secondary={(order.statusNotificacao === 'NOTIFICADO' || order.tipoImportacao === "SO") ? 'Já notificado' : null} />
                    </MenuItem>

                </Menu>

            </Grid>
            <Grid item xs={12}>
                {order.dataImportacao ? (
                    <React.Fragment>
                        <Button variant="contained"
                            color="grey"
                            fullWidth
                            endIcon={<UndoIcon />}
                            disabled={undoImportRunning || orderCanceled}
                            onClick={() => undoImportEvent(card)}>{undoImportRunning ? 'Desfazendo...' : 'Desfazer importação'}</Button>
                        {undoImportRunning ? <LinearProgress /> : null}
                    </React.Fragment>
                ) : (
                    <Button variant="contained"
                        color="grey"
                        fullWidth
                        endIcon={<InputIcon />}
                        disabled={orderCanceled}
                        onClick={openDetailOrder}>Importar</Button>
                )}

            </Grid>

            {isDelivery && (
                <Grid item xs={12}>
                    <SituacaoSelect card={card} />
                </Grid>
            )}

            <Grid item xs={12}>
                <OtherOptions card={card} dispatch={dispatch} cancelarPedidoKDS={cancelarPedidoKDS} />
            </Grid>

            <Grid item xs={12} mb={1}>
                <StyledButton variant="text"
                    className={classes.deleteBtn}
                    fullWidth
                    endIcon={<CancelIcon />}
                    disabled={orderCanceled}
                    onClick={() => handleCancelOrder(card)} >Cancelar</StyledButton>
            </Grid>
        </Grid >
    )
}

function SituacaoSelect({ card }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const order = card.data;
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const generalParameters = useSelector(state => state.generalParameters)
    const COLUNA_AGUARDANDO_RETIRADA_PDV = getParamByKey(generalParameters, 'COLUNA_AGUARDANDO_RETIRADA_PDV')?.valor;
    const { promptDialog } = usePdvContext()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSituacao = async (situacao) => {

        let motivoCancelamento = null
        if (situacao === 'CANCELADO') {
            motivoCancelamento = await promptDialog({
                content: 'Informe o motivo do cancelamento',
            })
        }

        const SITUACAO_ANTIGA = order.situacao

        changeSituacaoPedidoOnServer(order, situacao, motivoCancelamento)
            .then(pedidoIntegracao => {

                const SITUACAO_ATUAL = pedidoIntegracao.situacao;
                if ("CONFIRMADO" === SITUACAO_ATUAL && "PENDENTE" === SITUACAO_ANTIGA) {
                    onConfirmOrder(pedidoIntegracao, enqueueSnackbar, (respNotificationOrders) => {

                        respNotificationOrders.map((orderTemp) => {
                            dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderTemp })
                            dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderTemp } })

                            let updatedCard = createCardFromPedido(orderTemp)
                            dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })
                            return null;
                        });

                    })
                }


                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })
                enqueueSnackbar(`Pedido #${pedidoIntegracao.id} alterado para ${pedidoIntegracao.situacao}`, { variant: "success" });
                handleClose()
                //setOrder(pedidoIntegracao)
            })
            .catch(err => {
                console.error(err)
                extractErrorMessage(err, "Falha ao alterar status da situação")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            })

    };

    const situationOccurred = (order, situationType) => {
        // console.log("situationOccurred", order, situationType)
        if (order.situacao === situationType) {
            return true
        }

        if (situationType === 'PENDENTE') {
            return order.dataIntegracao !== undefined
        }

        if (situationType === 'CONFIRMADO') {
            return order.dataConfirmacao !== undefined
        }

        if (situationType === 'ENVIADO') {
            return order.dataEnvio !== undefined
        }

        if (situationType === 'ENTREGUE') {
            return order.dataEntrega !== undefined
        }

        if (situationType === 'CANCELADO') {
            return order.dataCancelamento !== undefined
        }
    };

    const getDateOfSituation = (order, situationType) => {

        if (situationType === 'PENDENTE') {
            return order.dataIntegracao ? dateFormat(order.dataIntegracao) : null
        }

        if (situationType === 'CONFIRMADO') {
            return order.dataConfirmacao ? dateFormat(order.dataConfirmacao) : null
        }

        if (situationType === 'ENVIADO') {
            return order.dataEnvio ? dateFormat(order.dataEnvio) : null
        }

        if (situationType === 'ENTREGUE') {
            return order.dataEntrega ? dateFormat(order.dataEntrega) : null
        }

        if (situationType === 'CANCELADO') {
            return order.dataCancelamento ? dateFormat(order.dataCancelamento) : null
        }
    };

    const getSituacoes = () => {
        // eslint-disable-next-line
        if (COLUNA_AGUARDANDO_RETIRADA_PDV == 0) {
            return SITUACOES.filter(el => el.key !== "AGUARDANDO_RETIRADA")
        } else {
            return SITUACOES
        }
    }

    return (
        <Root>
            <Button
                color="grey"
                variant="outlined"
                fullWidth aria-haspopup="true" onClick={handleClick} endIcon={<ExpandMoreIcon />}
                startIcon={
                    <CheckCircleIcon fontSize="small" style={{ color: getSituacaoBySigla(order.situacao).color }} />
                }>
                {getSituacaoBySigla(order.situacao).name}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >
                {getSituacoes().map(situacao => (
                    <MenuItem
                        key={situacao.key}
                        onClick={() => handleChangeSituacao(situacao.key)}
                        disabled={order.situacao === situacao.key}
                        selected={order.situacao === situacao.key}>

                        <ListItemAvatar>
                            <Avatar className={classes.smallAvatar} style={{ backgroundColor: situacao.color }}>
                                {situationOccurred(order, situacao.key) ? <DoneAllIcon fontSize="small" /> : <DoneIcon fontSize="small" />}
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText primary={situacao.singularName} secondary={getDateOfSituation(order, situacao.key)} />
                    </MenuItem>
                ))}
            </Menu>
        </Root>
    )
}

function OtherOptions({ card, dispatch, cancelarPedidoKDS }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const order = card.data;
    const tipoImportacao = getTipoImportacaoBySigla(card.data.tipoImportacao)
    const tipoImportacaoOriginal = getTipoImportacaoBySigla(card.data.tipoImportacaoOriginal)
    const orderCanceled = card.data.situacao === 'CANCELADO'
    const canConvertOrder = !order.dataImportacao
    const ROLE_CONVERTER_PEDIDOS_INTEGRACAO_PDV_SISCHEF = ifAnyGranted('ROLE_CONVERTER_PEDIDOS_INTEGRACAO_PDV_SISCHEF')
    const ROLE_PDV_COMANDA_MESA = ifAnyGrantedUnidade('ROLE_PDV_COMANDA_MESA')
    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString;
    const LABEL_IDENTIFICADOR_PRINCIPAL = MODELO_IDENTIFICADOR_PRINCIPAL === "MESA" ? "Mesa" : "Comanda"
    const [openOrderInfo, setOpenOrderInfo] = React.useState(false);
    const { promptDialog } = usePdvContext()
    const{ handleClickPrintNFApp } = useFunction()

    const handleClickOpenOrderInfo = () => {
        setOpenOrderInfo(true)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickCloseOrder = (event) => {
        if (order) {
            dispatch({ type: 'OPEN_DIALOG_CLOSE_ORDER', closeOrder: { open: true, order } })
        }
    }

    const handleClickGerarNFCe = () => {
        if (!ifAnyGrantedUnidade('ROLE_NOTA_FISCAL')) {
            enqueueSnackbar(`Permissão negada para emissão de documentos fiscais`, { variant: "warning" });
            return
        }

        if (!order.dataImportacao) {
            enqueueSnackbar(`Pedido #${order.id} precisa estar importado para gerar uma NFC-e/NF-e`, { variant: "warning" });
            return
        }

        dispatch({ type: 'TOOGLE_DIALOG_INVOICE', detailDialogInvoice: { open: true, order: order } })
    }


    const verifyIfAproved = (respNF) => {
        if (respNF && (respNF.status || respNF.status === 0)) {
            return [100, 103, 105, 0].includes(parseInt(respNF.status));
        }
        return false;
    }


    const handleImprimirNFCe = () => {        
        handleClickPrintNFApp()
    }

    const handleDeleteOrder = async (card) => {
        const justify = await promptDialog({
            content: 'Informe uma justificativa para excluir o pedido. ATENÇÃO: Não é possível desfazer',
        })

        if (justify) {
            if (justify.length < 5) {
                enqueueSnackbar('Informe uma justificativa maior.', { variant: "error" });
                return
            }

            let id = card.id

            let service = new AuthService();
            service.delete("/api-v2/pedidoIntegracao/" + id + "/" + justify)
                .then(response => response.json())
                .then(pedidoIntegracao => {

                    cancelarPedidoKDS(pedidoIntegracao, justify);

                    enqueueSnackbar('Pedido deletado com sucesso.', { variant: "success" });

                    dispatch({ type: 'REMOVE_ORDER_GENERIC', order: pedidoIntegracao })
                    dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: false, card: null } })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'REMOVE_ORDER_GENERIC', order: pedidoIntegracao } })

                }).catch(err => {
                    console.error(err)
                    extractErrorMessage(err, "Não foi possível desfazer a integração")
                        .then(msg => {
                            enqueueSnackbar(msg, { variant: "error" });
                        })

                })
        }
    }

    const handleUpdatedOrder = () => {
        let id = order.id
        let service = new AuthService()

        service.get(`/api-v2/pedidoIntegracao/atualizarPedido/${id}`)
            .then(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })

                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                enqueueSnackbar("Pedido atualizado com sucesso", { variant: "success" });
            })
            .catch(err => {
                err.response.json().then(elem => {
                    enqueueSnackbar(elem.errorMessage, { variant: "error" });
                    console.error(elem);
                })
            })
    }


    const handleConvertOrderToSischef = () => {

        if (!ROLE_CONVERTER_PEDIDOS_INTEGRACAO_PDV_SISCHEF) {
            enqueueSnackbar("Seu usuário não tem permissão para converter um pedido de integração (para poder ser editado)", { variant: "warning" });
            return;
        }

        dispatch({ type: 'TOOGLE_IMPORT_ORDER_DIALOG', importOrder: { open: false, card: card } }) //Fecha o dialog de import
        let id = order.id
        let service = new AuthService()

        service.post(`/api-v2/pedidoIntegracao/converterPedido/${id}`)
            .then(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })


                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })

                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                enqueueSnackbar("Pedido convertido com sucesso", { variant: "success" });
            })

            .catch(err => {
                err.response.json().then(elem => {
                    enqueueSnackbar(elem.errorMessage, { variant: "error" });
                    console.error(elem);
                })
            })
    }

    const handleUndoOrderConversion = () => {
        let id = order.id
        let service = new AuthService()

        service.post(`/api-v2/pedidoIntegracao/desfazerConversaoPedido/${id}`)
            .then(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })

                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                enqueueSnackbar("Pedido desconvertido com sucesso", { variant: "success" });
            })
            .catch(err => {
                err.response.json().then(elem => {
                    enqueueSnackbar(elem.errorMessage, { variant: "error" });
                    console.error(elem);
                })
            })
    }

    const handleAbrirListaLogistica = () => {
        dispatch({ type: 'TOOGLE_DIALOG_LOGISTICS', dialogLogistics: { open: true, order } })
    }

    const handleDesfazerLogistica = () => {
        let service = new AuthService()
        service.post(`/api-v2/integracaoLogistica/desfazerLogistica/${order?.id}/${order?.idConfiguracaoIntegracaoLogistica}`)
            .then(pedidoIntegracao => {
                let updatedCard = createCardFromPedido(pedidoIntegracao)
                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: true } })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                enqueueSnackbar("logística desfeita com sucesso", { variant: "success" });
            })
            .catch(err => {
                enqueueSnackbar("Erro ao desfazer a logística", { variant: "error" });
                console.error(err)
            })
    }

    /*
    *Função para converter um pedido de delivery em pedido de comanda e mesa, somente converte pedidos do tipo SO 
    *que não foram convertidos e que foram confirmados
    */
    const handleConvertOrderToComanda = async () => {

        if (!ROLE_PDV_COMANDA_MESA) {
            enqueueSnackbar('É necessário ter o módulo de Comanda/Mesa ativo para poder converter um pedido', { variant: "warning" });
            return
        }

        const justify = await promptDialog({
            inputLabel: 'Comanda',
            content: `Informe uma ${LABEL_IDENTIFICADOR_PRINCIPAL} para enviar o pedido. ATENÇÃO: Não é possível desfazer`
        })

        if (justify) {

            const strNum = justify.replace(/[^0-9]/g, '')

            if (strNum.length < 1) {
                enqueueSnackbar('Informe um valor válido', { variant: "error" });
                return
            }

            //Envia para o analytics a informação do clique no botão de converter pedido para comanda e mesa
            ReactGA.event({
                category: 'Button',
                action: 'Click converter mesa',
                label: 'Botão converter pedido para mesa'
            });

            let id = order.id
            let service = new AuthService()

            service.post(`/api-v2/pedidoIntegracao/converterPedidoComanda/${id}/${strNum}`)
                .then(pedidoIntegracao => {

                    let updatedCard = createCardFromPedido(pedidoIntegracao)
                    dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: false } })

                    dispatch({ type: 'REMOVE_ORDER_GENERIC', order: pedidoIntegracao, converted: true })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'REMOVE_ORDER_GENERIC', order: pedidoIntegracao } })

                    dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                    enqueueSnackbar("Pedido convertido com sucesso", { variant: "success" });
                })
                .catch(err => {
                    err.response.json().then(elem => {
                        enqueueSnackbar(elem.errorMessage, { variant: "error" });
                        console.error(elem);
                    })
                })
        }
    }

    const handleCopyOrderSummary = () => {
        const summary = generateOrderSummary(order)
        copyToClipboard(summary, enqueueSnackbar)
    }

    return (
        <Root>
            <Button
                color="grey"
                variant="contained"
                fullWidth aria-haspopup="true" onClick={handleClick} endIcon={<ExpandMoreIcon />}>
                + opções
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >

                <MenuItem onClick={handleClickGerarNFCe}>
                    <ListItemIcon>
                        {!verifyIfAproved(order.pedido) ? (                            
                            <CallMadeIcon fontSize="small" />)
                            : (
                            <PrintIcon fontSize="small"/>
                        )}
                    </ListItemIcon>
                    <ListItemText primary={!verifyIfAproved(order.pedido) ? "Gerar Nota Fiscal" : "Visualizar NFCe" } secondary={!verifyIfAproved(order.pedido) ? "NFC-e / NF-e / ECF" : ""} />
                </MenuItem>

                <MenuItem onClick={onClickCloseOrder}>
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Fechar pedido" secondary="Recalcular Tx Serviço e imprimir" />
                </MenuItem>

                {// eslint-disable-next-line
                    tipoImportacao?.sigla === 'SO' && !!!tipoImportacaoOriginal && canConvertOrder && order.tipo != 'MESA' && order.tipo != 'COMANDA' && (
                        <div>
                            <MenuItem onClick={handleConvertOrderToComanda} disabled={!canConvertOrder || orderCanceled}>
                                <ListItemIcon>
                                    <FlipIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={`Converter para pedido de '${LABEL_IDENTIFICADOR_PRINCIPAL}'`} secondary="Permite que os pedidos sejam visualizados na tela de comanda e mesa" />
                            </MenuItem>
                        </div>
                    )}

                {tipoImportacao?.integracao && (
                    <div>
                        <MenuItem onClick={handleUpdatedOrder}>
                            <ListItemIcon>
                                <RefreshIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Atualizar pedido" secondary="Atualiza o status buscando no marketplace" />
                        </MenuItem>


                        <MenuItem onClick={handleConvertOrderToSischef} disabled={!canConvertOrder || orderCanceled}>
                            <ListItemIcon>
                                <CompareIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Converter pedido" secondary="Permite que os pedidos sejam editáveis" />
                        </MenuItem>

                    </div>
                )}

                {order?.tipoImportacaoOriginal && ROLE_CONVERTER_PEDIDOS_INTEGRACAO_PDV_SISCHEF && (
                    <MenuItem onClick={handleUndoOrderConversion} disabled={!canConvertOrder || orderCanceled}>
                        <ListItemIcon>
                            <SettingsBackupRestoreIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Desfazer conversão" secondary="Volta o pedido ao estado original" />
                    </MenuItem>
                )}

                {order?.iFoodOrder?.deliveryMethod?.mode === 'DELIVERY' && order?.statusLogistica !== 'ENVIADO' ? (
                    <MenuItem onClick={() => handleAbrirListaLogistica()}>
                        <ListItemIcon>
                            <PinDropIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Enviar para logística"
                            secondary="Escolha a plataforma da logística" />
                    </MenuItem>
                ) : null}

                {order?.iFoodOrder?.deliveryMethod?.mode === 'DELIVERY' && order?.statusLogistica === 'ENVIADO' ?
                    (
                        <MenuItem onClick={() => handleDesfazerLogistica()}>
                            <ListItemIcon>
                                <UndoIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Desfazer logística"
                                secondary="Permite desfazer a logística" />
                        </MenuItem>
                    ) : null}

                <MenuItem onClick={() => handleClickOpenOrderInfo()}>
                    <ListItemIcon>
                        <DescriptionIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Informações para suporte"
                        secondary="Logs e informações adicionais" />
                </MenuItem>

                <MenuItem onClick={handleCopyOrderSummary}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Copiar resumo do pedido" secondary="Clique para copiar o resumo do pedido para a área de transferência" />
                </MenuItem>

                <MenuItem onClick={() => handleDeleteOrder(card)}
                    className={classes.deleteBtn}>
                    <ListItemIcon className={classes.deleteBtn}>
                        <DeleteOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Excluir pedido"
                        secondary="Excluir é diferente de cancelar. Não é possível desfazer" />
                </MenuItem>
            </Menu>

            <OrderInfo order={order} open={openOrderInfo} setOpen={setOpenOrderInfo} />
            <FiscalDocument />
            <DialogLogistic />
        </Root>
    )
}