import React from 'react';
import { useDispatch } from "react-redux"
import { useCardPrincipal } from '../Helper';
import { useConnectionStatus } from '../../../../../../util/http/HttpUtil';
import { IconButton, Tooltip } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function RealizarPagamentoButton({ identificadorCard, onLoading, disabled = false }) {
    const dispatch = useDispatch()
    const isConnected = useConnectionStatus()
    const [onClickOpenOrderAsync] = useCardPrincipal(identificadorCard)

    const onClickPayOrder = event => {

        //Quando nao é possível identificar qual pedido abrir
        const onOpenManyOrders = identificadorCard => {
            //SE TIVER MAIS DE UM PEDIDO PARA A MESMA COMANDA
            dispatch({
                type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR',
                dialogOrderListByIdentificador: {
                    open: true,
                    data: {
                        identificadorCard,
                        openCloseOrder: true,
                    }
                }
            })
        }

        //abre para realizar o pagamento
        const onOpenOneOrder = (orderCopy, initActiveStep) => {
            dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: orderCopy } })
        }

        const onFinally = () => onLoading ? onLoading(false) : null

        if (onLoading) onLoading(true)

        onClickOpenOrderAsync(onOpenManyOrders, onOpenOneOrder, onFinally)

        event.stopPropagation()
    }

    return (
        <Tooltip disableInteractive title="Realizar pagamento">
            <span>
                <IconButton size="medium" onClick={onClickPayOrder} color='success'>
                    <AttachMoneyIcon fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    )
}