import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import AuthService from '../../service/AuthService';
import { extractErrorMessage } from '../../util/http/HttpUtil';

function GenericCode({ setComplete }) {
    const service = new AuthService()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        service.get('/api-v2/codigoGenerico/chave/TIPO_NOTIFICACAO_PRODUTO,URL_SERVIDOR_IMPRESSAO,LIMITE_DESPACHO')
            .then(genericCodes => {
                console.log('generic codes', genericCodes)
                dispatch({ type: 'SEND_GENERAL_CODE', genericCodes: genericCodes })
                setComplete(true)
            }).catch(err => {
                extractErrorMessage(err, "Erro ao buscar os codigos genéricos")
                    .then(msg => {
                        enqueueSnackbar(`Erro ao buscar os codigos genéricos TIPO_NOTIFICACAO_PRODUTO,URL_SERVIDOR_IMPRESSAO - ${msg}`, { variant: "error" });
                    })
                setComplete(true)
                console.log('ERRO_CODIGO_GENERICO: ' + err)
            })
        // eslint-disable-next-line    
    }, [])

    return (
        <div>

        </div>
    );
}

export function getGenericCodeByKey(genericCodes, key) {
    let genericCodeByKey

    if (genericCodes && genericCodes.length > 0) {
        genericCodeByKey = genericCodes.filter((param) => param.chave === key)
    }

    return genericCodeByKey
}

export function hasGenericCodeByCode(genericCodes, code = '') {
    if (!genericCodes) {
        return false
    }

    return genericCodes.some(item => item.codigo === code)
}

export default GenericCode
