import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography,
    useMediaQuery,
    Badge,
    Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MoreVert from '@mui/icons-material/MoreVert';
import CircularProgressWithLabel from '../../../elements/CircularProgressWithLabel';
import { createCardFromPedido } from '../../../../../util/board/BoardUtil';
import FecharPedidoButton from './FecharPedidoButton';
import { useCardPrincipal } from './Helper';
import { useConnectionStatus } from '../../../../../util/http/HttpUtil';
import useHandleOfflineOrder from '../../../../../util/order/orderUtil';
import { useIntersectionObserver } from '../../../../../util/genericUtil';
import RealizarPagamentoButton from './RealizarPagamentoButton';

const PREFIX = 'CardIdentificadorPrincipal'

const classes = {
    root: `${PREFIX}-root`,
    card: `${PREFIX}-card`,
    cardHeader: `${PREFIX}-cardHeader`,
    cardContent: `${PREFIX}-cardContent`,
    customerName: `${PREFIX}-customerName`,
    openedCard: `${PREFIX}-openedCard`,
}

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
    [`&.${classes.root}`]: {
        overflow: "hidden"
    },

    [`& .${classes.card}`]: {
        height: theme.spacing(14),
    },

    [`& .${classes.cardHeader}`]: {
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.personal.header
    },

    [`& .${classes.cardContent}`]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },

    [`& .${classes.customerName}`]: {
        whiteSpace: 'nowrap',
        fontSize: '0.9em',
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(11),
        },
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(17),
        },
    },

    [`& .${classes.openedCard}`]: {

    },
}))

const LoadingCard = styled('div')(({ theme }) => ({
    '&': {
        width: '100%',
        height: '112px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(255, 255, 255, 0.12)'
    }
}))

const getLastItemOrdered = (identificadorCard) => {
    let lastUpdated = null
    const orderList = identificadorCard.orderList

    if (orderList) {
        orderList.map(order => {

            let createdAtOrder = new Date(order.createdAt)
            if (lastUpdated === null || lastUpdated.getTime() < createdAtOrder.getTime()) {
                lastUpdated = createdAtOrder
            }

            if (order.iFoodOrder?.items) {
                order.iFoodOrder.items.map(item => {
                    if (item.createdAt) {
                        let createdAtItem = new Date(item.createdAt)
                        if (lastUpdated === null || lastUpdated.getTime() < createdAtItem.getTime()) {
                            lastUpdated = createdAtItem
                        }
                    }
                    return null;
                })
            }
            return null;
        })
    }

    return lastUpdated;
}

const getNomeClienteFromListOrder = (orderList, identificador) => {
    let nomeCliente = "";

    if (identificador && identificador.pessoa) {
        nomeCliente = identificador?.pessoa.nome;
    } else {
        orderList.forEach((order) => {
            if (order.pessoaCliente?.nome && order.pessoaCliente?.nome !== 'CLIENTE FINAL') {
                nomeCliente = order.pessoaCliente?.nome;
                return true;
            }
            return false;
        });
    }
    return nomeCliente;
};

const CardIdentificadorPrincipalContent = memo(function CardIdentificadorPrincipalContent({ identificador, status, nomeCliente, tipo }) {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start">

            <Grid item>

                <Typography variant="h3" align='center'>
                    {identificador}
                </Typography>

            </Grid>

            {(status === "BLOQUEADO") && (
                <Grid item>
                    <Typography variant="h6" align="center" color="primary">
                        BLOQUEADA
                    </Typography>
                </Grid>
            )}

            <Grid item>
                <Box
                    component="div"
                    overflow="hidden"
                    textAlign="center"
                    textOverflow="ellipsis"
                    className={classes.customerName}>
                    {nomeCliente ? nomeCliente : tipo}
                </Box>
            </Grid>


        </Grid>
    )
})

const CardIdentificadorPrincipalAvatar = memo(function CardIdentificadorPrincipalAvatar({ countOrders, lastUpdatePercent, lastUpdatedOrderLabel }) {
    const infoIdentificador = useSelector(state => state.globalCache.infoIdentificador)
    const MODELO_IDENTIFICADOR_PRINCIPAL = infoIdentificador.MODELO_IDENTIFICADOR_PRINCIPAL

    if (countOrders > 1) {
        return (
            <Tooltip disableInteractive title={`${countOrders} pedido(s) nesta ${MODELO_IDENTIFICADOR_PRINCIPAL}`}>
                <Badge
                    color="primary"
                    overlap="circular"
                    badgeContent={countOrders}
                    variant="standard">
                    <CircularProgressWithLabel
                        thickness={2}
                        size={31}
                        value={lastUpdatePercent}
                        label={`${lastUpdatedOrderLabel}`} />
                </Badge>
            </Tooltip>
        )
    }

    return (
        <CircularProgressWithLabel
            thickness={2}
            size={31}
            value={lastUpdatePercent}
            label={`${lastUpdatedOrderLabel}`} />
    )
})

function CardIdentificadorPrincipal({ identificadorCard, tipo }) {
    const dispatch = useDispatch()
    const identificador = identificadorCard.identificador
    const orderListIdentificador = identificadorCard.orderList
    const openedCard = orderListIdentificador && orderListIdentificador.length > 0
    //const fisrtOrder = openedCard && orderList[0]

    const lastUpdatedOrder = openedCard ? getLastItemOrdered(identificadorCard) : null
    const nomeCliente = getNomeClienteFromListOrder(orderListIdentificador, identificador) // fisrtOrder.pessoaCliente?.nome === 'CLIENTE FINAL' ? "" : fisrtOrder.pessoaCliente?.nome
    const [loading, setLoading] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const countOrders = orderListIdentificador && orderListIdentificador.length

    const [onClickOpenOrderAsync] = useCardPrincipal(identificadorCard);
    const isConnected = useConnectionStatus()
    const handleOfflineOrder = useHandleOfflineOrder()


    /* INFORMACOES IMPORTANTES PARA O CARD */
    const infoCard = {}

    const [ref, inView] = useIntersectionObserver({ updateOn: infoCard })

    if (lastUpdatedOrder) {
        var diffMs = (new Date() - lastUpdatedOrder);

        var diffMins = Math.abs(parseInt((diffMs / 1000.0) / 60.0));
        infoCard.lastUpdatedOrderInMinuts = diffMins
        infoCard.lastUpdatedOrderInHours = diffMins / 60

        let lastUpdatedOrderLabel = diffMins + "m";
        if (infoCard.lastUpdatedOrderInHours > 1) {
            lastUpdatedOrderLabel = parseInt(infoCard.lastUpdatedOrderInHours) + "h";
        }
        infoCard.lastUpdatedOrderLabel = lastUpdatedOrderLabel;
        let percent = parseInt((diffMins / 60.0 * 100))
        infoCard.lastUpdatePercent = percent > 100 ? 100.0 : percent;
    }

    const onClickOpenOrder = () => {

        //Quando nao é possível identificar qual pedido abrir
        const onOpenManyOrders = (identificadorCard) => {
            dispatch({
                type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR',
                dialogOrderListByIdentificador: {
                    open: true,
                    data: { identificadorCard }
                }
            })
        }

        //abre de edição de produtos
        const onOpenOneOrder = (orderCopy, initActiveStep) => {
            dispatch({
                type: 'TOOGLE_DIALOG_ORDER',
                dialogOrder: {
                    open: true,
                    order: orderCopy,
                    initActiveStep
                }
            })
        }

        const onFinally = () => setLoading(false)

        if (!isConnected) {
            handleOfflineOrder({ identificadorId: identificador.id })
            return
        }

        setLoading(true)

        onClickOpenOrderAsync(onOpenManyOrders, onOpenOneOrder, onFinally)
    }

    const onClickOpenDetailOrder = (event) => {

        //Quando nao é possível identificar qual pedido abrir
        const onOpenManyOrders = (identificadorCard) => {
            //SE TIVER MAIS DE UM PEDIDO PARA A MESMA COMANDA
            dispatch({
                type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR',
                dialogOrderListByIdentificador: {
                    open: true,
                    data: { identificadorCard }
                }
            })
        }


        //abre de edição de produtos
        const onOpenOneOrder = (orderCopy, initActiveStep) => {
            let cardFromOrder = createCardFromPedido(orderCopy)
            dispatch({
                type: 'TOOGLE_DETAIL_ORDER_DIALOG',
                detailOrder: {
                    open: true,
                    card: cardFromOrder
                }
            })
        }

        const onFinally = () => setLoading(false)

        if (!isConnected) {
            handleOfflineOrder({ identificadorId: identificador.id })
            return
        }

        setLoading(true)

        onClickOpenOrderAsync(onOpenManyOrders, onOpenOneOrder, onFinally)

        event.stopPropagation()
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div ref={ref}>
            {!inView
                ? <LoadingCard />
                : <StyledCardActionArea component="a" onClick={onClickOpenOrder}
                    onMouseLeave={handlePopoverClose}
                    onMouseEnter={handlePopoverOpen}
                    className={clsx([classes.root])}>
                    <Card
                        variant="outlined"
                        className={clsx([classes.card, openedCard && classes.openedCard])}>

                        <CardHeader
                            className={clsx(openedCard && classes.cardHeader)}
                            title=""
                            subheader=""
                            avatar={
                                infoCard.lastUpdatedOrderInMinuts !== undefined ?
                                    <CardIdentificadorPrincipalAvatar
                                        countOrders={countOrders}
                                        lastUpdatePercent={infoCard.lastUpdatePercent}
                                        lastUpdatedOrderLabel={infoCard.lastUpdatedOrderLabel} /> : null
                            }
                            action={
                                openedCard && (
                                    <>
                                        {anchorEl && (!isMobile) &&
                                            <FecharPedidoButton
                                                identificadorCard={identificadorCard}
                                                onLoading={(param) => setLoading(param)} />
                                        }

                                        {anchorEl && (!isMobile) &&
                                        <RealizarPagamentoButton
                                            identificadorCard={identificadorCard}
                                            onLoading={(param) => setLoading(param)} />
                                        }

                                        <Tooltip disableInteractive title='Detalhar'>
                                            <IconButton
                                                size="medium"
                                                onClick={onClickOpenDetailOrder}>
                                                <MoreVert />
                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        />

                        <CardContent className={classes.cardContent}>
                            {loading ? <LinearProgress /> : null}

                            <CardIdentificadorPrincipalContent
                                tipo={tipo}
                                nomeCliente={nomeCliente}
                                status={identificador.status}
                                identificador={identificador.identificador} />

                        </CardContent>
                    </Card>
                </StyledCardActionArea>
            }
        </div>
    )
}

export default CardIdentificadorPrincipal