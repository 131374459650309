import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Menu,
    MenuItem,
    Tooltip,
    Grid,
    Divider,
    ListItemButton,
    Collapse,
    TableRow,
    TableCell,
    Typography,
    Button,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import AuthService from '../../../../service/AuthService';
import { extractErrorMessage } from '../../../../util/http/HttpUtil';
import IntegrationAvatar from '../../../layout-components/Board/IntegrationAvatar';
import { AvatarOrderByType } from '../../../layout-components/Board/typeOrderAvatar';
import { AvatarForIdentificador } from '../../../layout-components/elements/AvatarForIdentificador';
import { CircularProgressWithLabelLinearGradiente } from '../../../layout-components/elements/CircularProgressWithLabel';
import { getSituacaoKdsByKey, imprimirItemsKDS, itemKdsIsCancelado } from '../../utils';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PrintIcon from '@mui/icons-material/Print';
import { changeSituacaoPedidoOnServer, showMsgErrorOuSuccessOnReturPrint } from '../../../../util/board/BoardUtil';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dateFormat, quantityFormat } from '../../../../util/formatter';
import { Alert } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { AGENDADO_COLOR, TAKEOUT_COLOR } from '../../../../theme/palette';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import { getGenericCodeByKey } from '../../../../component/genericCode';
import { useDispatch, useSelector } from 'react-redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const PREFIX = 'CardKds'

const classes = {
    root: `${PREFIX}-root`,
    itemCancelado: `${PREFIX}-itemCancelado`,
    cardHeader: `${PREFIX}-cardHeader`,
    cardContent: `${PREFIX}-cardContent`,
    avatarSmall: `${PREFIX}-avatarSmall`,
    nested: `${PREFIX}-nested`,
    subItensList: `${PREFIX}-subItensList`,
    kdsList: `${PREFIX}-kdsList`,
    item: `${PREFIX}-item`
}

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: 0,
    },

    [`& .${classes.itemCancelado}`]: {
        fontWeight: 'bold',
        textDecoration: 'line-through'
    },

    [`& .${classes.cardHeader}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.palette.personal.header
    },

    [`& .${classes.cardContent}`]: {
        padding: theme.spacing(0),
        paddingBottom: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },

    [`& .${classes.avatarSmall}`]: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    [`& .${classes.nested}`]: {
        paddingLeft: theme.spacing(2),
        paddingTop: 0
    },

    [`& .${classes.subItensList}`]: {
        paddingBottom: theme.spacing(1.5),
    },

    [`& .${classes.kdsList}`]: {
        //maxHeight: "450px", 
        //overflow: "auto"  
    }
}))

export default function CardKds({ cardKds }) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const infoCard = {}
    const firstOrder = cardKds[0]
    const notificationCreatedAt = new Date(cardKds[0].createdAt) || null
    const pedidoKds = firstOrder.pedidoKds
    const address = firstOrder?.deliveryAddress
    const typeOrder = pedidoKds.tipoPedido
    const isTakeout = pedidoKds.operacao === 'TAKEOUT'
    const isAgendado = pedidoKds.agendado
    const isComandaOuMesa = typeOrder === 'COMANDA' || typeOrder === 'MESA'
    const savedSettings = JSON.parse(localStorage.getItem('_saved_settings_kds_'))
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com/'

    const service = new AuthService(URL_SERVIDOR_IMPRESSAO)
    const [itemKdsSelected, setItemKdsSelect] = useState()
    const [anchorElItem, setAnchorElItem] = useState(null)

    const hasSomePendente = useMemo(() => cardKds.some(card => card?.item?.status === 'PENDENTE'), [cardKds])

    // só vai habilitar o botão de "Todos prontos" caso o status de todos forem INICIADO ou todos forem CONCLUIDO
    const isAllDone = useMemo(() => cardKds.every(card => card?.item?.status === 'INICIADO') || cardKds.every(card => card?.item?.status === 'CONCLUIDO'), [cardKds])

    const handleClickMoreActionItem = (event, itemKds) => {
        setAnchorElItem(event.currentTarget)
        setItemKdsSelect(itemKds)
    }

    const handleCloseActionItem = () => {
        setAnchorElItem(null)
    }

    if (notificationCreatedAt) {
        var diffMs = (new Date() - notificationCreatedAt)
        var diffMins = Math.abs(parseInt((diffMs / 1000.0) / 60.0))

        infoCard.lastNotificationInMinuts = diffMins
        infoCard.lastNotificationInHours = diffMins / 60

        let notificationLabel = diffMins + "m"

        if (infoCard.lastNotificationInHours > 1) {
            notificationLabel = parseInt(infoCard.lastNotificationInHours) + "h"
        }

        infoCard.notificationLabel = notificationLabel

        let percent = parseInt((diffMins / 60.0 * 100))
        infoCard.notificationPercent = percent > 100 ? 100.0 : percent
    }

    function alterarStatusDoItem(itemKds) {
        service.post('/api/kds/updateItemStatus', itemKds).then(itemKdsResp => {
            // enqueueSnackbar(`Alterado a situação do item ${itemKdsResp.item.descricao} para ${itemKdsResp.item.status}`, { variant: "success" });
        }).catch(err => {
            extractErrorMessage(err, "Falha ao alterar a situação do item")
                .then(msg => enqueueSnackbar("Não foi possível alterar a situação do item." + msg, { variant: "error" }))
        }).finally(() => handleCloseActionItem())
    }

    const onClickVoltarItem = (itemKds) => {
        let novaSituacao = ''
        const situacaoItem = getSituacaoKdsByKey(itemKds.item.status).key

        if (situacaoItem === 'PENDENTE') {
            novaSituacao = 'DESPACHADO'
        } else if (situacaoItem === 'INICIADO') {
            novaSituacao = 'PENDENTE'
        } else if (situacaoItem === 'CONCLUIDO') {
            novaSituacao = 'INICIADO'
        } else if (situacaoItem === 'DESPACHADO') {
            novaSituacao = 'CONCLUIDO'
        } else {
            novaSituacao = 'PENDENTE'
        }

        itemKds.item.status = novaSituacao

        alterarStatusDoItem(itemKds)
    }

    function getProximaSituacao(situacaoAtual) {
        let novaSituacao = situacaoAtual

        if (situacaoAtual === 'PENDENTE') {
            novaSituacao = 'INICIADO'
        } else if (situacaoAtual === 'INICIADO') {
            novaSituacao = 'CONCLUIDO'
        } else if (situacaoAtual === 'CONCLUIDO') {
            novaSituacao = 'DESPACHADO'
        } else if (situacaoAtual === 'CANCELADO') {
            novaSituacao = 'CANCELAMENTO_ACEITO'
        }

        return getSituacaoKdsByKey(novaSituacao)
    }

    const onClickAvancarItem = (itemsKds) => {
        const cardKdsCopy = []
        const itemsKdsCopy = [itemsKds].flat()

        itemsKdsCopy.forEach((itemKds) => {
            const situacaoItem = getSituacaoKdsByKey(itemKds.item.status).key
            const novaSituacao = getProximaSituacao(situacaoItem).key

            itemKds.item.status = novaSituacao
            cardKdsCopy.push(itemKds)
        })

        alterarStatusDoItem(cardKdsCopy)
    }

    const onClickImprimirItem = (itemKds) => {
        if (!savedSettings.localDeImpressao || savedSettings.localDeImpressao === '-') {
            enqueueSnackbar('Configure um local de impressão clicando no canto superior direito', { variant: "error" });
            return
        }

        imprimirItemsKDS([itemKds], savedSettings.localDeImpressao).then(resp => {
            showMsgErrorOuSuccessOnReturPrint(resp, enqueueSnackbar)
        }).catch(err => {
            console.error('Erro ao enviar item para impressao. Retorno: ', err)
            enqueueSnackbar('Erro ao enviar item para impressao.', { variant: "error" });
        })
    }

    const onClickIniciarTodos = (itemsKds) => {
        const cardKdsCopy = []
        const itemsKdsCopy = [itemsKds].flat()

        itemsKdsCopy.forEach((itemKds) => {
            if (itemKds?.item?.status !== 'PENDENTE') {
                return
            }

            itemKds.item.status = getProximaSituacao(itemKds.item.status).key
            cardKdsCopy.push(itemKds)
        })

        alterarStatusDoItem(cardKdsCopy)
    }

    const onClickTodosProntos = (itemsKds) => {
        const cardKdsCopy = []
        const itemsKdsCopy = [itemsKds].flat()

        notificaSocket(itemsKdsCopy[0])

        itemsKdsCopy.forEach((itemKds) => {
            if (!['CONCLUIDO', 'INICIADO'].includes(itemKds?.item?.status)) {
                return
            }

            itemKds.item.status = getProximaSituacao(itemKds.item.status).key
            cardKdsCopy.push(itemKds)
        })

        alterarStatusDoItem(cardKdsCopy)
    }

    const notificaSocket = (itemKds) => {
        const pedido = itemKds.pedidoKds
        const status = itemKds.item.status

        // o concluido só existe no KDS então não precisa notificar o PDV
        if (status === 'INICIADO') return

        changeSituacaoPedidoOnServer(pedido, converteStatusSituacao(status))
            .then(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })
                enqueueSnackbar(`Pedido #${pedidoIntegracao.id} alterado para ${pedidoIntegracao.situacao}`, { variant: "success" });
            }).catch(err => {
                console.error(err)
                extractErrorMessage(err, 'Falha ao alterar status da situação')
                    .then(msg => enqueueSnackbar(msg, { variant: 'error' }))
            })
    }

    const converteStatusSituacao = (status) => ({
        'CONCLUIDO': 'ENVIADO',
    }[status])

    return (
        <StyledCard variant="outlined" className={clsx([classes.root])}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}>
                            <Grid item xs>
                                <ListItem dense style={{ padding: 0 }} >
                                    <ListItemText
                                        primary={pedidoKds.pessoaCliente?.nome}
                                        secondary={`PED ${pedidoKds.id} ${pedidoKds.sequencia ? '| SEQ ' + pedidoKds.sequencia : ''} ${pedidoKds.complementoIdentificador ? '| ' + pedidoKds.complementoIdentificador : ''}`}
                                    />
                                </ListItem>
                            </Grid>
                            {isTakeout && (
                                <Grid item>
                                    <Tooltip title='Para retirar'>
                                        <StorefrontIcon style={{ color: TAKEOUT_COLOR }} />
                                    </Tooltip>
                                </Grid>
                            )}
                            {isAgendado && (
                                <Grid item>
                                    <Tooltip title={`Pedido Agendado para ${dateFormat(pedidoKds.dataPedido)}`}>
                                        <EventIcon style={{ color: AGENDADO_COLOR }} />
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid item>
                                {isComandaOuMesa ? (
                                    <AvatarForIdentificador order={pedidoKds} size="small" />
                                ) : (
                                    <AvatarOrderByType type={typeOrder} />
                                )}
                            </Grid>
                            <Grid item>
                                <IntegrationAvatar pedido={pedidoKds} />
                            </Grid>
                        </Grid>

                        {address?.neighborhood && (
                            <Grid display="flex"
                                alignItems="flex-end"
                                justifyContent="flex-end">
                                <Tooltip title={address?.formatedAdress}>
                                    <Typography sx={{
                                        gap: "2px",
                                        color: "text.secondary",
                                        alignItems: "flex-start",
                                        display: "flex",
                                    }}>
                                        <LocationOnIcon fontSize="small" />
                                        {address.neighborhood}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )}
                    </>
                }
                avatar={
                    infoCard.lastNotificationInMinuts > 0 &&
                    <CircularProgressWithLabelLinearGradiente
                        thickness={2}
                        size={35}
                        value={infoCard.notificationPercent}
                        label={`${infoCard.notificationLabel}`} />
                }
            />

            {savedSettings.agrupamento === 'AGRUPADO' && (
                <Box sx={{
                    p: 1,
                    gap: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Button
                        fullWidth
                        variant="outlined"
                        disabled={!hasSomePendente}
                        startIcon={<PlayArrowIcon />}
                        onClick={() => onClickIniciarTodos(cardKds)}>
                        Iniciar todos
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        disabled={!isAllDone}
                        startIcon={<DoneAllIcon />}
                        onClick={() => onClickTodosProntos(cardKds)}>
                        Todos prontos
                    </Button>
                </Box>
            )}

            <CardContent className={classes.cardContent}>

                <List className={classes.kdsList}>
                    {cardKds.map((itemKds, index) => {
                        const isCancelado = itemKdsIsCancelado(itemKds)
                        const semProximoStatus = getProximaSituacao(itemKds.item.status).key === itemKds.item.status

                        return (
                            <React.Fragment key={index}>
                                <ListItem secondaryAction={
                                    isCancelado ?
                                        <Tooltip title={`Motivo: ${itemKds.item.justificativaCancelamento}`}>
                                            <IconButton edge="end" size="large">
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <IconButton
                                            edge="end"
                                            onClick={(event) => handleClickMoreActionItem(event, itemKds)}
                                            size="large">
                                            <MoreVertIcon />
                                        </IconButton>
                                } disablePadding>

                                    <ListItemButton disabled={semProximoStatus} onClick={() => onClickAvancarItem(itemKds)}>

                                        <ListItemAvatar>
                                            <Avatar className={classes.avatarSmall} sizes='small' alt={getSituacaoKdsByKey(itemKds.item.status).singularName}
                                                style={getSituacaoKdsByKey(itemKds.item.status).style}>
                                                {getSituacaoKdsByKey(itemKds.item.status)?.singularName?.charAt(0)}
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText primary={
                                            <span className={isCancelado ? classes.itemCancelado : undefined}>
                                                {`${quantityFormat(itemKds.item.quantidade, 1, 1)} x  ${itemKds.item.descricao} ${itemKds.item.observacao ? `( ${itemKds.item.observacao} )` : ''}`}
                                            </span>
                                        } />

                                    </ListItemButton>

                                </ListItem>

                                <Collapse in={true} timeout="auto" unmountOnExit>
                                    {itemKds.item.subItemKDS?.length > 0 && (
                                        <List
                                            component="div"
                                            disablePadding
                                            className={classes.subItensList}>

                                            {itemKds.item.subItemKDS?.map((subItem, index) => (
                                                <ListItem
                                                    disableGutters
                                                    disabled={semProximoStatus}
                                                    dense
                                                    key={index}
                                                    className={classes.nested}>
                                                    <ListItemIcon>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        secondary={
                                                            <span className={isCancelado ? classes.itemCancelado : undefined}>
                                                                {`${quantityFormat(subItem.quantidade, 3, 0)} x ${subItem.descricao} ${subItem.observacao ? `( ${subItem.observacao} )` : ''}`}
                                                            </span>
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </Collapse>
                            </React.Fragment>
                        )
                    })}
                </List>

                {itemKdsSelected && itemKdsSelected !== null &&

                    <Menu
                        anchorEl={anchorElItem}
                        open={Boolean(anchorElItem)}
                        onClose={handleCloseActionItem}>
                        <MenuItem
                            disabled={getSituacaoKdsByKey(itemKdsSelected.item.status).key === 'DESPACHADO'}
                            onClick={() => onClickAvancarItem(itemKdsSelected)}>

                            <ListItemIcon>
                                <NavigateNextIcon fontSize="small" />
                            </ListItemIcon>

                            {` Marcar como '${getProximaSituacao(itemKdsSelected.item.status).singularName}'`}
                        </MenuItem>
                        <MenuItem
                            disabled={getSituacaoKdsByKey(itemKdsSelected.item.status).key === 'PENDENTE'}
                            onClick={() => onClickVoltarItem(itemKdsSelected)}>
                            <ListItemIcon>
                                <NavigateBeforeIcon fontSize="small" />
                            </ListItemIcon>
                            Etapa anterior
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => onClickImprimirItem(itemKdsSelected)}>
                            <ListItemIcon>
                                <PrintIcon fontSize="small" />
                            </ListItemIcon>
                            Imprimir
                        </MenuItem>
                        <Divider />
                        <MenuItem sx={{ justifyContent: 'space-between' }}>
                            <Typography>Vendedor</Typography>
                            <Typography>{itemKdsSelected?.item?.pessoaVendedor?.nome ? itemKdsSelected.item.pessoaVendedor.nome : 'Não informado'}</Typography>
                        </MenuItem>
                    </Menu>
                }

                {firstOrder && firstOrder.pedidoKds && firstOrder.pedidoKds.observacao &&
                    <Alert variant="outlined" severity="info" color="info">
                        {firstOrder.pedidoKds.observacao}
                    </Alert>
                }
            </CardContent>
        </StyledCard>
    )
}