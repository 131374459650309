import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import CustomDateFilter from '../../../component/generics/customDateFilter'
import AuthService from '../../../service/AuthService'
import MapContainer from './HeatMap/index'
import ResumeHeatMap from './Resume'
import ViewListIcon from '@mui/icons-material/ViewList'
import { Alert, AlertTitle } from '@mui/material'
import { formatFullAddressCustomer } from '../../../util/customer/helper'
import ReactGA from "react-ga4"
import { currencyFormat } from '../../../util/formatter'
import Checkbox from '@mui/material/Checkbox'
import { getDefaultPeriodFormated } from '../../../helpers/dateHelper'
import { Box, Button, Container, FormControl, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Tooltip, Typography, } from '@mui/material'
import { autoPaginationRequest, extractErrorMessage } from '../../../util/http/HttpUtil'

const PREFIX = 'SalesHeatMap'

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    scrollBar: `${PREFIX}-scrollBar`
}

const MainContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.main}`]: {
        marginTop: theme.spacing(5),
    },

    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.scrollBar}`]: theme.scrollBar
}))

export default function SalesHeatMap() {
    const service = new AuthService()
    const profile = service.getProfile()

    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(getDefaultPeriodFormated())
    const [customerList, setCustomerList] = useState([])
    const [openResumeHeatMap, setOpenResumeHeatMap] = useState(false)
    const [refreshPositionProfile, setRefreshPositionProfile] = useState(false)
    const [typeWeight, setTypeWeight] = useState('CURRENCY') //QUANTITY 
    const [markers, setMarkers] = useState([])
    const [positions, setPositions] = useState([])
    const [ticketMedioGeral, setTicketMedioGeral] = useState(0)
    const [initialCenter, setInitialCenter] = useState({ lat: profile.unidade.pessoa.latitude || 0, lng: profile.unidade.pessoa.longitude || 0 })

    useEffect(() => {//informa ao analytics que o usuário caiu na pagina de salesHeatMap
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "salesHeatMap" })
    }, [])

    /**
     * Aqui é feito a contagem e a soma dos pedidos de cada cliente
     */
    function listCustomerByOrder(orderList) {

        if (orderList.length <= 0) {
            enqueueSnackbar('Nenhum pedido encontrado no período', { variant: 'warning' })
            return
        }

        let customerListTemp = []
        let valorTotalPedidos = 0.0
        let quantidadeTotalPedidos = 0.0

        orderList.map((order) => {
            let valorInicial = 0.0
            const soma = order.itens.reduce((acumulador, item) => acumulador + item.valorTotal, valorInicial)

            valorTotalPedidos += soma
            quantidadeTotalPedidos += 1

            let customer = customerListTemp.find(el => el.id === order.pessoa.id)

            if (!customer) {
                customer = order.pessoa
                customer.quantidadePedido = 1
                customer.valorPedido = soma
                customer.ticketMedio = soma

                customerListTemp.push(customer)

            } else {
                customer.quantidadePedido = (customer.quantidadePedido || 0) + 1
                customer.valorPedido = (customer.valorPedido || 0.0) + soma
                customer.ticketMedio = customer.valorPedido / customer.quantidadePedido
            }

            customer.isValidLatLng = (customer.latitude !== undefined && customer.latitude !== 0) && (customer.longitude !== undefined && customer.longitude !== 0)
            return null
        })

        setCustomerList(customerListTemp)

        const ticketMedioGeral = valorTotalPedidos / quantidadeTotalPedidos
        setTicketMedioGeral(ticketMedioGeral)
    }
    /**
     * Aqui é preparado os dados para exibir no mapa, ou seja é percorrido os clientes e montado a lista com as latitudes e longitudes
     */
    function prepararDadosParaMapa() {
        if (customerList && customerList.length > 0) {
            let combinedMarkers = []

            customerList.forEach((customer) => {
                if (customer.isValidLatLng) {
                    // Checa se já existe um marcador com as mesmas coordenadas
                    let existingMarker = combinedMarkers.find(marker =>
                        marker.lat === customer.latitude && marker.lng === customer.longitude
                    )
                    if (existingMarker) {
                        // Combina as informações com o marcador existente
                        existingMarker.quantidadePedido += customer.quantidadePedido
                        existingMarker.valorPedido += customer.valorPedido
                        existingMarker.ticketMedio = existingMarker.valorPedido / existingMarker.quantidadePedido
                    } else {
                        // Se não houver sobreposição, adiciona um novo marcador
                        combinedMarkers.push({
                            lat: customer.latitude,
                            lng: customer.longitude,
                            valorPedido: customer.valorPedido,
                            quantidadePedido: customer.quantidadePedido,
                            ticketMedio: customer.valorPedido / customer.quantidadePedido,
                        })
                    }
                }
            })

            // Atualiza os estados com os marcadores combinados
            setMarkers(combinedMarkers.map(marker => ({
                ...marker,
                valorPedido: currencyFormat(marker.valorPedido),
                ticketMedio: currencyFormat(marker.ticketMedio),
                quantidadePedido: marker.quantidadePedido
            })))

            // Atualiza os pesos das posições para o mapa de calor
            setPositions(combinedMarkers.map(marker => ({
                lat: marker.lat,
                lng: marker.lng,
            })))
        }
    }

    async function getLatLng(customer) {
        if ((!customer.latitude || 0) || (!customer.longitude || 0)) {
            try {
                setRefreshPositionProfile(true)
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(formatFullAddressCustomer(customer).address)}&pt-BR&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
                if (!response.ok) throw new Error('Falha na obtenção da resposta da API do Google')

                const data = await response.json()
                const { lat, lng } = data.results[0].geometry.location

                customer.latitude = lat
                customer.longitude = lng
                customer.isValidLatLng = true

                setInitialCenter({ lat, lng })

                return customer
            } catch (error) {
                console.error('Erro ao atualizar coordenadas', error)
                enqueueSnackbar('Erro ao atualizar coordenadas.', { variant: 'error' })
                return false
            } finally {
                setRefreshPositionProfile(false)
            }
        }
    }

    const onClickRefreshLatLng = () => {
        getLatLng(profile.unidade.pessoa)
    }

    useEffect(() => {
        prepararDadosParaMapa()
        // eslint-disable-next-line
    }, [customerList])

    useEffect(() => {
        prepararDadosParaMapa()
        // eslint-disable-next-line
    }, [typeWeight])

    const handleChangetypeWeight = (event) => {
        setPositions([])
        setTypeWeight(event.target.value)
    }

    async function onClickSearch() {
        setPositions([])

        if (!filter) {
            enqueueSnackbar('Nenhum período informado', { variant: 'error' })
            return
        }

        setLoading(true)

        try {
            const totalPedidos = await service.get(`/api-v2/pedido/listCountPedidos?dataInicial=${filter?.startDate}&dataFinal=${filter?.endDate}`)

            const data = await autoPaginationRequest({
                filter,
                totalCount: totalPedidos,
                startDate: filter?.startDate,
                endDate: filter?.endDate,
                endpoint: '/api-v2/pedido/listPedidos',
            })

            listCustomerByOrder(data.map(item => item.docs).flat())
        } catch (err) {
            console.error(err)
            const msg = await extractErrorMessage(err, 'Erro ao buscar pedidos')
            enqueueSnackbar(`Erro ao buscar pedidos - ${msg}`, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const handleChangePeriodo = (period) => {
        setFilter(period)
    }

    const onClickListAll = () => {
        setOpenResumeHeatMap(true)
    }

    function getCustomerNotValidLatLng() {
        return customerList.filter((customer) => customer.isValidLatLng === false)
    }

    const [filters, setFilters] = useState({
        showHeatMap: false,
        showMarkers: true,
        showCircle: false,
    })

    const menuOptions = [
        // { id: "1", label: "Detalhar Pontos de Calor", subLabel: "Código id do pedido", type: "id", value: "showHeatMap", checked: filters.showHeatMap },
        { id: "2", label: "Exibir Pontos no Mapa", subLabel: "Código sequencial do pedido", type: "seq", value: "showMarkers", checked: filters.showMarkers },
        { id: "3", label: "Pedidos por Distância", subLabel: "Código de referência do pedido", type: "ref", value: "showCircle", checked: filters.showCircle },
    ]

    return (
        <>
            <MainContainer component="main" className={classes.main} maxWidth={false}>
                <Typography variant="h2" component="h1">
                    Mapa de calor
                </Typography>

                <Typography variant="h5" component="h2">
                    Veja os locais com as maiores vendas no seu delivery
                </Typography>

                <Box paddingBottom={2} paddingTop={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <CustomDateFilter onChange={handleChangePeriodo} />
                            {loading && <LinearProgress color="secondary" />}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                size="md"
                                color="primary"
                                variant='outlined'
                                fullWidth
                                disabled={loading}
                                sx={{ mt: 0.5 }}
                                onClick={() => onClickSearch()}>
                                {loading ? 'Buscando...' : 'Buscar'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box pb={3}>
                    <Box pt={2} pb={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={10} sm={11}>
                                <Tooltip title="Nesta opção você pode alterar a importância dos pedidos no mapa.">
                                    <FormControl component="fieldset" disabled={customerList?.length <= 0}>
                                        <RadioGroup row
                                            name="controlled-radio-buttons-group"
                                            value={typeWeight}
                                            onChange={handleChangetypeWeight}>
                                            <FormControlLabel value="CURRENCY" control={<Radio />} label="Valor" />
                                            <FormControlLabel value="QUANTITY" control={<Radio />} label="Quantidade" />
                                            <FormControlLabel value="AVG_TICKET" control={<Radio />} label="Ticket Médio" />
                                            <FormControlLabel value="TODOS" control={<Radio />} label="Todos" />
                                        </RadioGroup>

                                        <Box>
                                            {menuOptions.map(option => (
                                                <FormControlLabel
                                                    key={option.id}
                                                    label={option.label}
                                                    control={
                                                        <Checkbox
                                                            checked={filters[option.value]}
                                                            onChange={(event) => setFilters(prev => ({ ...prev, [option.value]: event.target.checked }))} />
                                                    } />
                                            ))}
                                        </Box>
                                    </FormControl>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title="Listagem das vendas">
                                    <span>
                                        <Button
                                            color="grey"
                                            size="medium"
                                            onClick={onClickListAll}
                                            disabled={customerList?.length <= 0}>
                                            <ViewListIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>

                    {(initialCenter.lat === 0 || initialCenter.lng === 0) &&
                        <Alert severity="warning">
                            <AlertTitle >Aviso</AlertTitle>
                            {`Não foi informado as coordenadas geográficas ( latitude e longitude ) no cadastro da empresa.`}

                            <Button onClick={onClickRefreshLatLng}>
                                Clique aqui para atualizar
                            </Button>
                        </Alert>
                    }

                    {getCustomerNotValidLatLng().length > 0 &&
                        <Alert severity="warning">
                            <AlertTitle >Aviso</AlertTitle>
                            {`Existem ${getCustomerNotValidLatLng().length} pessoas sem as coordenadas geográficas ( latitude e longitude ).`}

                            <Button disabled={refreshPositionProfile} onClick={onClickListAll}>
                                Clique aqui para atualizar
                            </Button>
                        </Alert>
                    }

                    {(initialCenter.lat !== 0 || initialCenter.lng !== 0) &&
                        <MapContainer
                            positions={positions}
                            initialCenter={initialCenter}
                            markers={markers}
                        showMarkers={filters.showMarkers}
                        showHeatMap={filters.showHeatMap}
                        typeWeight={typeWeight}
                        ticketMedioGeral={ticketMedioGeral}
                        customerList={customerList}
                        showCircle={filters.showCircle} />
                    }
                </Box>
            </MainContainer>

            <ResumeHeatMap open={openResumeHeatMap}
                setOpen={setOpenResumeHeatMap}
                customerList={customerList}
                setCustomerList={setCustomerList}
                getLatLng={getLatLng} />
        </>
    )
}