import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePdvContext } from '../../../component/generics/PdvProvider'

export function PopUpOrder() {
    const dispatch = useDispatch()
    const { confirmDialog } = usePdvContext()

    const open = useSelector(state => state.dialogPopUpOrder.open)
    const oldOrder = useSelector(state => state.dialogPopUpOrder.oldOrder)
    const editOrder = useSelector(state => state.dialogPopUpOrder.editOrder)

    useEffect(() => {
        const itemsIfood = []
        if (!!editOrder) {
            editOrder.iFoodOrder.items.forEach(item => {
                if (item?.notSaved)
                    itemsIfood.push(item)
            })
        }

        const existeDivergencia = itemsIfood.some(newItems => {
            return !oldOrder.iFoodOrder.items.some(oldItem => oldItem.referenceCode === newItems.referenceCode)
        })

        const isDifferentLength = oldOrder?.iFoodOrder?.items?.length !== editOrder?.iFoodOrder?.items?.length

        if (open && (existeDivergencia || isDifferentLength)) {
            confirmDialog({
                content: 'Esse pedido sofreu alterações, deseja incluí-las?',
                confirmLabel: 'Atualizar',
                showCancelButton: false,
            }).then(() => {
                const newOrder = { ...oldOrder }
                let itemsIFoodOrder = oldOrder?.iFoodOrder?.items
                if (itemsIfood.length > 0)
                    itemsIFoodOrder.push(itemsIfood)

                newOrder.iFoodOrder.items = itemsIFoodOrder.flat()

                dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { order: newOrder, open } })
            }).catch(() => dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { open: false } }))
        }

        dispatch({ type: 'TOOGLE_DIALOG_POPUP_ORDER', dialogPopUpOrder: { oldOrder, editOrder, open: false } })
    }, [open])

    return <></>
}