import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {
    AppBar,
    Box,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Tooltip,
} from '@mui/material';
import AuthService from '../../../../service/AuthService';
import { useSnackbar } from 'notistack';
import { extractErrorMessage, useConnectionStatus } from '../../../../util/http/HttpUtil';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { OrderObj } from '../../NewOrder/helper/OrderHelper';
import { getSettings } from '../../../settings/settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListAllOrder from '../../ListAllOrder';
import { getTypeOrderBykey } from '../../../../util/board/BoardUtil';
import InfoIcon from '@mui/icons-material/Info';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormControl } from '@mui/material';
import { Popover } from '@mui/material';
import ListOfflineOrders from './ListOfflineOrders';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReactGA from "react-ga4";
import { getKey } from '../../../../util/indexedDB/IdbUtils';
import useHandleOfflineOrder from '../../../../util/order/orderUtil';

const PREFIX = 'FindMenuIdentificador'

const classes = {
    findBar: `${PREFIX}-findBar`
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.findBar}`]: {
        boxShadow: 'none',
        top: theme.myAppBar.height,
        zIndex: theme.zIndex.appBar - 10,
    }
}))

function FindMenuIdentificador({ props }) {
    const { enqueueSnackbar } = useSnackbar();
    const identificadorPrincipalRef = useRef()
    const btnAddOrderRef = useRef()
    const btnAttachMoney = useRef()
    const btnMergeType = useRef()
    const btnViewList = useRef()
    const btnAdditionalInformation = useRef()
    const [pedidoSelecionado, setPedidoSelecionado] = useState()
    const [todosPedidosSelecionados, setTodosPedidosSelecionados] = useState()
    const [identificador, setIdentificador] = useState(null)
    const [msgErroBusca, setMsgErroBusca] = useState("")
    const dispatch = useDispatch()
    const cardList = useSelector(state => state.cards) //Lista todos os cards

    const [fezBusca, setFezBusca] = useState(false) // essa variavel é para saber quando habilitar os botões, pois precisamos saber se foi feito a busca na api
    const [buscando, setBuscando] = useState(false)

    const infoIdentificador = useSelector(state => state.globalCache.infoIdentificador)
    const MODELO_IDENTIFICADOR_PRINCIPAL = infoIdentificador.MODELO_IDENTIFICADOR_PRINCIPAL
    const focusOnInputIdentificadorPrincipal = useSelector(state => state.globalCache.focusOnInputIdentificadorPrincipal)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const savedOrderBy = useSelector(state => state?.orderBy?.orderBy) //Pega o estado atual do orderBy
    const [orderBy, setOrderBy] = React.useState(savedOrderBy); // Salva no state o estado do redux
    const isConnected = useConnectionStatus()
    const handleOfflineOrder = useHandleOfflineOrder()

    useHotkeys('Ctrl+x', (event) => {
        event.preventDefault()

        if (btnAttachMoney.current && !btnAttachMoney.current.disabled) {
            btnAttachMoney.current.click()
        }
    });

    useHotkeys('Ctrl+i', (event) => {
        event.preventDefault()

        if (btnAdditionalInformation.current && !btnAdditionalInformation.current.disabled) {
            btnAdditionalInformation.current.click()
        }
    });


    // useHotkeys('ins', (event) => {
    //     event.preventDefault()

    //     if (btnAddOrderRef.current && !btnAddOrderRef.current.disabled) {
    //         btnAddOrderRef.current.click()
    //     }
    // });

    useHotkeys('ctrl+j', (event) => {
        event.preventDefault()

        if (btnMergeType.current && !btnMergeType.current.disabled) {
            btnMergeType.current.click()
        }
    });

    useHotkeys('ctrl+o', (event) => {
        event.preventDefault()

        if (btnViewList.current && !btnViewList.current.disabled) {
            btnViewList.current.click()
        }
    });

    useHotkeys('Ctrl+f', (event) => {
        event.preventDefault()
        identificadorPrincipalRef.current.focus()
    });


    const onBuscarPedidoPeloIdentificadorPrincipal = (identificadorPrincipal, tipos) => {
        let service = new AuthService();
        return service //.get faz a busca no banco de dados suas funções de busca do pedido integração são encontrados em PedidoIntegracaoAPI.java
            .get(`/api-v2/pedidoIntegracao/listByIdentificadorPrincipal?identificadorPrincipal=${identificadorPrincipal}&tipos=${tipos}&importado=false&pago=NAO&cancelado=false`);

    }


    const buscarPedidoPeloIdentificadorPrincipal = (identificadorPrincipal, tipos) => {
        setBuscando(true)

        if (!isValidIdentificador(identificadorPrincipal)) {
            setMsgErroBusca(`A ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal} não existe.`)
            identificadorPrincipalRef.current.select()
            setBuscando(false)
            return
        }

        if (!isConnected) {
            getKey('Cards').then(result => {
                const idbCard = result.find(item => item.identificador.identificador === identificadorPrincipal)

                setPedidoSelecionado(idbCard)
                setMsgErroBusca('')
                setFezBusca(true)
                setBuscando(false)
            })
            return
        }


        onBuscarPedidoPeloIdentificadorPrincipal(identificadorPrincipal, tipos)
            .then(pedidos => {
                setMsgErroBusca("")

                if (!pedidos.length) {
                    abrirPedidoSelecionado(null)
                    setPedidoSelecionado(null)
                    return
                }

                let selecionarPedido = null; //define o pedido padrão como null, caso não tenha pedidos na comanda será o utilizado
                setIdentificador(identificadorPrincipal)

                setTodosPedidosSelecionados(pedidos)

                const pedidosSischef = pedidos.filter(pedido => pedido.tipoImportacao === 'SO' && pedidos.tipoImportacaoOriginal === undefined)

                if (pedidosSischef.length === 1) {
                    selecionarPedido = pedidosSischef[0]
                    abrirPedidoSelecionado(selecionarPedido)
                    setPedidoSelecionado(selecionarPedido)
                    return
                }

                if (pedidos.length > 1) {
                    let identificadorCard = cardList.find((card) => card.identificador.identificador === identificadorPrincipal)
                    dispatch({
                        type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } }
                    })
                    return
                }

                abrirPedidoSelecionado(selecionarPedido)
                setPedidoSelecionado(selecionarPedido)

            }).catch(err => {
                extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            }).finally(() => {

                setFezBusca(true)
                setBuscando(false)
            })
    }

    // const buscarPedidoPeloIdentificadorPrincipalOLD = (identificadorPrincipal, tipos) => {
    //     setBuscando(true)

    //     if (!isValidIdentificador(identificadorPrincipal)) {
    //         setMsgErroBusca(`A ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal} não existe.`)
    //         identificadorPrincipalRef.current.select()
    //         setBuscando(false)
    //         return
    //     }

    //     if (!isConnected) {
    //         getKey('Cards').then(result => {
    //             const idbCard = result.find(item => item.identificador.identificador === identificadorPrincipal)

    //             setPedidoSelecionado(idbCard)
    //             setMsgErroBusca('')
    //             setFezBusca(true)
    //             setBuscando(false)
    //         })
    //         return
    //     }

    //     let service = new AuthService();
    //     service //.get faz a busca no banco de dados suas funções de busca do pedido integração são encontrados em PedidoIntegracaoAPI.java
    //         .get(`/api-v2/pedidoIntegracao/listByIdentificadorPrincipal?identificadorPrincipal=${identificadorPrincipal}&tipos=${tipos}&importado=false&pago=NAO&cancelado=false`)
    //         .then(pedidos => {
    //             setMsgErroBusca("")

    //             if (!pedidos.length) {
    //                 abrirPedidoSelecionado(null)
    //                 setPedidoSelecionado(null)
    //                 return
    //             }

    //             let selecionarPedido = null; //define o pedido padrão como null, caso não tenha pedidos na comanda será o utilizado
    //             setIdentificador(identificadorPrincipal)

    //             setTodosPedidosSelecionados(pedidos)

    //             const pedidosSischef = pedidos.filter(pedido => pedido.tipoImportacao === 'SO' && pedidos.tipoImportacaoOriginal === undefined)

    //             if (pedidosSischef.length === 1) {
    //                 selecionarPedido = pedidosSischef[0]
    //                 abrirPedidoSelecionado(selecionarPedido)
    //                 setPedidoSelecionado(selecionarPedido)
    //                 return
    //             }

    //             if (pedidos.length > 1) {
    //                 let identificadorCard = cardList.find((card) => card.identificador.identificador === identificadorPrincipal)
    //                 dispatch({
    //                     type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } }
    //                 })
    //                 return
    //             }

    //             abrirPedidoSelecionado(selecionarPedido)
    //             setPedidoSelecionado(selecionarPedido)

    //         }).catch(err => {
    //             extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
    //                 .then(msg => {
    //                     enqueueSnackbar(msg, { variant: "error" });
    //                 })
    //         }).finally(() => {

    //             setFezBusca(true)
    //             setBuscando(false)
    //         })
    // }

    const handleKeyDownIdentificadorPrincipal = event => {

        if (13 === event.keyCode && !buscando && identificadorPrincipalRef.current) {
            buscarPedidoPeloIdentificadorPrincipal(identificadorPrincipalRef.current.value, MODELO_IDENTIFICADOR_PRINCIPAL)
        }
    }

    const handleChangeIdentificadorPrincipal = event => {
        identificadorPrincipalRef.current.value = event.target.value
    }

    const onClickPagarPedidos = () => {
        //Envia para o analytics a informação do clique no botão de pagar pedidos
        ReactGA.event({
            category: 'Button',
            action: 'Click pagar pedido(ctrl+x)',
            label: 'Botão pagar pedido com atalho'
        });

        let identificadorPrincipal = identificadorPrincipalRef.current.value;

        if (!isValidIdentificador(identificadorPrincipal)) {
            setMsgErroBusca(`A ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal} não existe.`)
            identificadorPrincipalRef.current.select()
            setBuscando(false)
            return
        }

        if (!isConnected) {
            getKey('Cards').then(result => {
                const idbCard = result.find(item => item.identificador.identificador === identificadorPrincipal)
                setPedidoSelecionado(idbCard)
                setMsgErroBusca('')
                setFezBusca(true)
                setBuscando(false)
            })
            return
        }

        setBuscando(true)

        onBuscarPedidoPeloIdentificadorPrincipal(identificadorPrincipal, MODELO_IDENTIFICADOR_PRINCIPAL)
            .then(pedidos => {

        //SE TIVER MAIS DE UM PEDIDO PARA A MESMA COMANDA
                if (pedidos && pedidos.length > 1) {

                    let testCard = cardList.filter((card) => card.identificador.identificador === identificador)//retorna uma lista, mas como só existe um identificador retorna uma lista com um elemento, depois é selecionado o elemento 0 da lista
                    let identificadorCard = testCard[0] //Busca o card pelo identificador para enviar o identificador correto na exibição de pagamentos
                    // a variável deve possuir o nome identificadorCard para listar os pedidos corretamente
                    dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } } })
                    //abre a tela para listar todos os pedidos de uma comanda
                } else if (pedidos.length == 1) {
                    let pedidoIntegracao = pedidos[0];
                    onClickPayment(pedidoIntegracao)
                } else {
                    setMsgErroBusca(`Nenhum pedido encontrado para a ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal}`)
                }

            }).catch(err => {
                extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            }).finally(() => {
                setBuscando(false)
            })

    }

    const onClickPayment = (pedidoIntegracao) => {
        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: pedidoIntegracao } })

        identificadorPrincipalRef.current.value = null

        handleOnFocus()
        setMsgErroBusca(null)
    }

    const onClickJoinOrder = () => {
        //Envia para o analytics a informação do clique no botão de juntar ou transferir pedidos
        ReactGA.event({
            category: 'Button',
            action: 'Click juntar ou transferir',
            label: 'Botão juntar ou transferir pedidos'
        });
        dispatch({ type: 'TOOGLE_DIALOG_JOIN_ORDERS', dialogJoinOrders: { open: true } })
    }

    const onClickListAllOrder = () => {
        //Envia para o analytics a informação do clique no botão da lista de pedidos
        ReactGA.event({
            category: 'Button',
            action: 'Click pedidos',
            label: 'Botão lista de pedidos'
        });
        dispatch({ type: 'TOOGLE_DIALOG_ALL_ORDER', dialogListAllOrder: { open: true } })
    }

    const onClickAdditionalInformation = () => {
        //Envia para o analytics a informação do clique no botão informações adicionais do pedido
        ReactGA.event({
            category: 'Button',
            action: 'Click adicional info',
            label: 'Botão informações adicionais do pedido'
        });


        let identificadorPrincipal = identificadorPrincipalRef.current.value;

        if (!isValidIdentificador(identificadorPrincipal)) {
            setMsgErroBusca(`A ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal} não existe.`)
            identificadorPrincipalRef.current.select()
            setBuscando(false)
            return
        }

        if (!isConnected) {
            getKey('Cards').then(result => {
                const idbCard = result.find(item => item.identificador.identificador === identificadorPrincipal)
                setPedidoSelecionado(idbCard)
                setMsgErroBusca('')
                setFezBusca(true)
                setBuscando(false)
            })
            return
        }

        setBuscando(true)

        onBuscarPedidoPeloIdentificadorPrincipal(identificadorPrincipal, MODELO_IDENTIFICADOR_PRINCIPAL)
            .then(pedidos => {

                if (pedidos && pedidos.length > 1) {

                    let testCard = cardList.filter((card) => card.identificador.identificador === identificador)//retorna uma lista, mas como só existe um identificador retorna uma lista com um elemento, depois é selecionado o elemento 0 da lista
                    let identificadorCard = testCard[0] //Busca o card pelo identificador para enviar o identificador correto na exibição de pagamentos
                    // a variável deve possuir o nome identificadorCard para listar os pedidos corretamente
                    dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } } })
                    //abre a tela para listar todos os pedidos de uma comanda
                    setMsgErroBusca(null)
                    identificadorPrincipalRef.current.value = null
                } else if (pedidos.length == 1) {
                    let pedidoIntegracao = pedidos[0];
                    dispatch({
                        type: 'ADD_UPDATE_ADDITIONAL_INFORMATION_ON_ORDER',
                        additionalInformation: { open: true, order: pedidoIntegracao }
                    })
                    setMsgErroBusca(null)
                    identificadorPrincipalRef.current.value = null
                } else {
                    setMsgErroBusca(`Nenhum pedido encontrado para a ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipal}`)
                }

            }).catch(err => {
                extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            }).finally(() => {
                setBuscando(false)

            })

    }

    const handleOnFocus = event => {
        if (identificadorPrincipalRef.current) {
            // identificadorPrincipalRef.current.focus()
            setPedidoSelecionado(null)
            setFezBusca(false)
        }
    }

    function isValidIdentificador(identificadorPrincipalValue) {
        setMsgErroBusca('')

        const filteredCard = cardList.filter((card) => card.identificador.tipo === MODELO_IDENTIFICADOR_PRINCIPAL)

        //Localiza o card que tem o pedido pelo identificador
        return filteredCard.find((card) => (card.identificador.identificador === identificadorPrincipalValue))

    }

    const abrirPedidoSelecionado = (pedidoAtual) => {
        const identificadorPrincipalValue = identificadorPrincipalRef.current.value
        let orderCopy;
        let initActiveStep = 0;

        if (!isValidIdentificador(identificadorPrincipalValue)) {
            setMsgErroBusca(`A ${MODELO_IDENTIFICADOR_PRINCIPAL}: ${identificadorPrincipalValue} não existe.`)
            identificadorPrincipalRef.current.select()
            return
        }

        if (!isConnected) {
            handleOfflineOrder({ identificadorId: pedidoAtual.identificador.id })
            return
        }

        if (pedidoAtual && pedidoAtual.identificadorPrincipal === identificadorPrincipalValue && pedidoAtual.tipoImportacao === 'SO' && pedidoAtual.tipoImportacaoOriginal === undefined) {
            //PEDIDO ENCONTRADO
            //Verifica se o pedido selecionado é um pedido sischef
            //caso seja irá editar esse pedido
            orderCopy = Object.assign({}, pedidoAtual);
            initActiveStep = 1;
        } else {
            //PEDIDO NOVO
            //Caso o pedido não seja sischef ou não exista pedido na comanda irá gerar um novo pedido estilo sischef
            orderCopy = new OrderObj()
            orderCopy.tipo = MODELO_IDENTIFICADOR_PRINCIPAL
            orderCopy.identificadorPrincipal = identificadorPrincipalValue
            orderCopy.printOrder = getSettings.printAutomaticallyOnCreate === "ENABLED";
            initActiveStep = 0
        }

        /* LIMPA O CAMPO DE BUSCA */
        identificadorPrincipalRef.current.value = null

        handleOnFocus()
        setMsgErroBusca(null)

        //abre de edição de produtos
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: orderCopy, initActiveStep } })
    }

    const onClickAddOrUpdateOrder = () => {
        if (identificadorPrincipalRef.current && !identificadorPrincipalRef.current.disabled) {
            buscarPedidoPeloIdentificadorPrincipal(identificadorPrincipalRef.current.value, MODELO_IDENTIFICADOR_PRINCIPAL);
        }
    }
    

    useEffect(() => {

        if (identificadorPrincipalRef.current.value) {
            btnAddOrderRef.current.focus()
        } else {
            handleOnFocus()
        }

    }, [pedidoSelecionado])

    useEffect(() => {

        if (fezBusca === true) {
            btnAddOrderRef.current.focus()
        }

    }, [fezBusca])

    useEffect(() => {

        if (focusOnInputIdentificadorPrincipal) {
            identificadorPrincipalRef.current.focus();
            dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { focusOnInputIdentificadorPrincipal: false } });
        }
    }, [focusOnInputIdentificadorPrincipal]);


    const handleClick = (event) => {
        //Envia para o analytics a informação do clique no botão de filtro das comandas
        ReactGA.event({
            category: 'Button',
            action: 'Click filtro comandas',
            label: 'Botão de filtro por comandas abertas'
        });
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setOrderBy(event.target.value)
    }

    useEffect(() => {
        dispatch({ type: 'TOGGLE_ORDER_BY_MODE', orderBy: orderBy }) //Atualiza estado do redux espelho do localStorage
    }, [orderBy])

    const open = Boolean(anchorEl);

    return (
        <Root>
            <AppBar position="sticky" color="inherit" className={classes.findBar}>

                <Box>
                    <Grid container spacing={1} alignItems="center">

                        <Grid item xs={12} sm={6}>
                            <Box p={0.5} flexGrow={2} >
                                <TextField
                                    disabled={!cardList}
                                    label={`Informe a ${getTypeOrderBykey(MODELO_IDENTIFICADOR_PRINCIPAL).descricao} (crtl+f)`}
                                    placeholder="'Enter' para buscar ou iniciar pedido"
                                    variant="filled"
                                    // autoFocus
                                    error={!!msgErroBusca}
                                    helperText={msgErroBusca}
                                    fullWidth
                                    size="medium"
                                    inputRef={identificadorPrincipalRef}
                                    onKeyDown={handleKeyDownIdentificadorPrincipal}
                                    onChange={handleChangeIdentificadorPrincipal}
                                    onFocus={handleOnFocus}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Tooltip title="Criar ou editar pedido">
                                                    <span>
                                                        <Fab color="primary" size="small" variant="circular" ref={btnAddOrderRef} onClick={onClickAddOrUpdateOrder} >
                                                            <AddCircleOutlineIcon />
                                                        </Fab>
                                                    </span>
                                                </Tooltip>
                                            </InputAdornment>,
                                        disableUnderline: true
                                    }}
                                />
                                {buscando &&
                                    <LinearProgress color="secondary" />
                                }
                            </Box>
                        </Grid>

                        <Grid item >
                            <Tooltip title="'Ctrl+X' - Realizar pagamento">
                                <Box component="span" >
                                    <IconButton
                                        ref={btnAttachMoney}
                                        onClick={onClickPagarPedidos}
                                        size="large">
                                        <AttachMoneyIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Grid>


                        <Grid item >
                            <Tooltip title="'Ctrl+I' - Informações adicionais do pedido">
                                <Box component="span">
                                    <IconButton
                                        ref={btnAdditionalInformation}
                                        onClick={onClickAdditionalInformation}

                                        size="large">
                                        <InfoIcon />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Grid>

                        <Grid item >
                            <Tooltip title="'Ctrl+J' - Juntar ou transferir pedidos">
                                <IconButton ref={btnMergeType} onClick={onClickJoinOrder} size="large">
                                    <MergeTypeIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="'Ctrl+O' - Listagem de pedidos">
                                <IconButton ref={btnViewList} onClick={onClickListAllOrder} size="large">
                                    <ViewListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <ListOfflineOrders />
                        </Grid>

                        <Grid container item xs alignItems="flex-end" justifyContent="flex-end">

                            <Box display="flex" flexDirection="row-reverse" >
                                <IconButton onClick={handleClick} size="large">
                                    <FilterListIcon />
                                </IconButton >
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                    <Box p={1} textAlign="center">
                                        <FormControl component="fieldset">
                                            <RadioGroup name="OrderBy" onChange={handleChange} value={orderBy}>
                                                <FormControlLabel value={"opens"} control={<Radio />} label={"Ordenar por " + MODELO_IDENTIFICADOR_PRINCIPAL?.toLowerCase() + "s abertas"} />
                                                <FormControlLabel value={"ids"} control={<Radio />} label={"Ordenar pelo número da " + MODELO_IDENTIFICADOR_PRINCIPAL?.toLowerCase()} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Popover>

                            </Box>

                        </Grid>

                        {/* 
                        <Grid container item xs alignItems="flex-end" justifyContent="flex-end">
                           
                            <Box display="flex" flexDirection="row-reverse" >
                                <IconButton  >
                                        <FilterListIcon />
                                </IconButton>
                            </Box>
                              
                        </Grid>
                        */}

                    </Grid>

                </Box>
            </AppBar>

            <ListAllOrder />

        </Root>
    );
}


export default FindMenuIdentificador;