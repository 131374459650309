export const toogledialogPopUpOrder = (currentState, action) => {
    let { dialogPopUpOrder }= action
  
    return {
      ...currentState,
      dialogPopUpOrder: { ...dialogPopUpOrder, oldOrder: dialogPopUpOrder.oldOrder, editOrder: dialogPopUpOrder.editOrder, open: dialogPopUpOrder.open }
    }
  }
  
  export const initialdialogPopUpOrder = () => {
    return {
      open: false,
      oldOrder: null,
      editOrder: null,
    }
  }