import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import { MainManuList, LogoutButton } from './menuItens';
import { Avatar, Box, Fab, ListItemIcon, Menu, MenuItem, Tooltip, ListItemText } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom";
import { OrderObj } from './NewOrder/helper/OrderHelper';
import { getSettings } from '../settings/settings';
import { getTypeOrderBykey } from '../../util/board/BoardUtil';
import packageJson from '../../../package.json';
import { ifAnyGranted } from '../../util/auth/authUtil';
import ReactGA from "react-ga4";


const PREFIX = 'SideList';

const classes = {
  extendedIcon: `${PREFIX}-extendedIcon`
};

const StyledFab = styled(Fab)(({ theme }) => ({
  [`& .${classes.extendedIcon}`]: {
    marginLeft: theme.spacing(1),
  }
}));

export default function SideList({ open }) {

  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const infoIdentificador = useSelector(state => state.globalCache.infoIdentificador);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handClickOpenNewOrder = () => {
    //Envia para o analytics a informação do clique no botão de novo pedido de delivery
    ReactGA.event({
      category: 'Button',
      action: 'Click new delivery',
      label: 'Botão novo pedido de delivery'
    });

    if (location.pathname !== "/delivery") {
      history.push("/delivery");
    }

    if (ifAnyGranted('ROLE_PDV_DELIVERY')) {
      const order = new OrderObj()
      order.printOrder = getSettings().printAutomaticallyOnCreate === "ENABLED";
      dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order } })
      handleClose()
    }

  }

  const handClickOpenNewOrderBalcao = () => {
    //Envia para o analytics a informação do clique no botão de novo pedido de balcão
    ReactGA.event({
      category: 'Button',
      action: 'Click new balcao',
      label: 'Botão novo pedido balcao'
    });

    if (location.pathname !== "/delivery") {
      history.push("/delivery");
    }

    if (ifAnyGranted('ROLE_PDV_DELIVERY')) {
      const order = new OrderObj()
      order.tipo = 'BALCAO'
      order.printOrder = getSettings().printAutomaticallyOnCreate === "ENABLED";
      dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order, initActiveStep: 1 } })
      handleClose()
    }
  }

  const handClickOpenNewOrderComanda = () => {
    //Envia para o analytics a informação do clique no botão de novo pedido de comanda
    ReactGA.event({
      category: 'Button',
      action: 'Click new comanda',
      label: 'Botão novo pedido de comanda'
    });

    if (location.pathname !== "/pedidosIdentificador") {
      history.push("/pedidosIdentificador");
    }

    // const order = new OrderObj()
    // order.tipo = 'COMANDA'
    // order.printOrder = getSettings().printAutomaticallyOnCreate === "ENABLED";
    // order.identificadorPrincipalEditavel = true;
    dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { focusOnInputIdentificadorPrincipal: true } })
    handleClose()
  }

  const DeliveryIcon = getTypeOrderBykey('DELIVERY').iconComponent;
  const BalcaoIcon = getTypeOrderBykey('BALCAO').iconComponent;
  const IdentificadorIcon = getTypeOrderBykey(infoIdentificador.MODELO_IDENTIFICADOR_PRINCIPAL).iconComponent;


  return (
    <>
      <Box display="flex" justifyContent="center" p={0} mt={2}>

        <StyledFab
          aria-controls="menu-novo-pedido"
          aria-haspopup="true"
          variant={open ? "extended" : 'circular'}
          color="primary"
          size='medium'

          onClick={handleClick}
        >{open && 'Novo pedido'} <AddCircleOutlineIcon className={clsx(open && classes.extendedIcon)} />
        </StyledFab>

        <Menu
          id="menu-novo-pedido"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          elevation={2}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handClickOpenNewOrder} >
            <ListItemIcon>
              <DeliveryIcon />
            </ListItemIcon>
            <ListItemText variant="inherit" primary="Novo pedido de delivery" secondary="atalho 'Insert'"></ListItemText>
          </MenuItem>

          <Tooltip title="" aria-label="Pedido de balcão" placement="right">
            <MenuItem onClick={handClickOpenNewOrderBalcao}>
              <ListItemIcon>
                <BalcaoIcon />
              </ListItemIcon>
              <ListItemText variant="inherit" primary='Novo pedido de balcão' secondary="atalho 'Home'"></ListItemText>
            </MenuItem>
          </Tooltip>

          <MenuItem onClick={handClickOpenNewOrderComanda}>
            <ListItemIcon>
              <IdentificadorIcon />
            </ListItemIcon>
            <ListItemText variant="inherit" primary={`Novo pedido de ${infoIdentificador.LABEL_IDENTIFICADOR_PRINCIPAL}`}></ListItemText>
          </MenuItem>

        </Menu>

      </Box>


      <Box display="flex" flexDirection='column' justifyContent="center" mt={1}>
        <List><MainManuList /></List>
      </Box>

      <Box
        display="flex"
        alignItems="flex-end"
        flexGrow={1}
        sx={{ height: 100 }}
      >
        <Box flexGrow={1} pb={2}>
          <LogoutButton />
          {open && <Box pt={1} textAlign="center" color="text.disabled">versão {packageJson.version}</Box>}

        </Box>
      </Box>
    </>
  );
}
