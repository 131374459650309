import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Tooltip } from '@mui/material';
import { getTypeOrderBykey } from '../../../util/board/BoardUtil';

const PREFIX = 'TypeOrderAvatar'

const classes = {
  avatar: `${PREFIX}-avatar`
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}))

const TypeOrderAvatar = ({ order }) => {

  return <AvatarOrderByType type={order?.tipo} />
}

export const AvatarOrderByType = memo(({ type }) => {
  const typeOrder = getTypeOrderBykey(type)
  const IconByType = typeOrder.iconComponent;

  return (
    <Tooltip title={typeOrder?.descricao} >
      <IconByType alt={typeOrder?.descricao} color="secondary" fontSize="medium" />
    </Tooltip>
  )
})

export default TypeOrderAvatar;