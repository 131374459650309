import AuthService from '../../service/AuthService';
import { dateFormat } from "../formatter";
import iconeDelivery from '../../../src/images/iconeDelivery.svg';
import iconeComanda from '../../../src/images/iconeComanda.svg';
import iconeBalcao from '../../../src/images/iconeBalcao.svg';
import iconeVendaRapida from '../../../src/images/iconeVendaRapida.svg';
import { addSeconds } from 'date-fns';
import { useSelector } from 'react-redux';
import { getParamByKey } from '../../component/parameter';



import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import DiningIcon from '@mui/icons-material/Dining';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

export const SITUACOES = [
    { key: 'PENDENTE', color: '#ffc107', name: "Pendentes", singularName: "Pendente" },
    { key: 'CONFIRMADO', color: '#4caf50', name: "Confirmados", singularName: "Confirmado" },
    { key: 'AGUARDANDO_RETIRADA', color: '#22b3dd', name: "Aguardando retirada", singularName: "Aguardando retirada" },
    { key: 'ENVIADO', color: '#795548', name: "Enviados", singularName: "Enviado" },
    { key: 'ENTREGUE', color: '#bdbdbd', name: "Entregues", singularName: "Entregue" },
    { key: 'CANCELADO', color: '#ef5350', name: "Cancelados", singularName: "Cancelado" },
]

export const TIPOS_IMPORTACOES = [
    /* TIPOS INTERNOS */
    { key: 'SISCHEF_ONLINE', descricao: "Sischef", sigla: "SO", integracao: false },
    { key: 'FACEBOOK', descricao: "Facebook", sigla: "FB", integracao: false },
    { key: 'TELEFONE', descricao: "Telefone", sigla: "TF", integracao: false },
    { key: 'WHATSAPP', descricao: "WhatsApp", sigla: "WA", integracao: false },
    { key: 'INSTAGRAM', descricao: "Instagram", sigla: "IN", integracao: false },
    /* IMPORTACOES */
    { key: 'IFOOD', descricao: "iFood", sigla: "IF", integracao: true },
    { key: 'RAPPI', descricao: "Rappi", sigla: "RA", integracao: true },
    { key: 'NEEMO', descricao: "Neemo", sigla: "NE", integracao: true },
    { key: 'DDIRETO', descricao: "Delivery Direto - V1", sigla: "DD", integracao: true },
    { key: 'QRPEDIR', descricao: "QrPedir", sigla: "QR", integracao: true },
    { key: 'ACCON', descricao: "Accon", sigla: "AC", integracao: true },
    { key: 'UBEREATS', descricao: "UberEats", sigla: "UE", integracao: true },
    { key: 'PEDZAP', descricao: "Pedzap", sigla: "PZ", integracao: true },
    { key: 'ANOTAAI', descricao: "Anota Ai", sigla: "AI", integracao: true },
    { key: 'DELIVERYMUCH', descricao: "Delivery Much", sigla: "DM", integracao: true },
    { key: 'DELIVRE', descricao: "DeLivre", sigla: "DL", integracao: true },
    { key: 'AIQFOME', descricao: "Aiqfome", sigla: "AF", integracao: true },
    { key: 'GOOMER', descricao: "Goomer", sigla: "GO", integracao: true },
    { key: 'FACITY', descricao: "Facity", sigla: "FC", integracao: true },
    { key: 'OUTROS_APPS', descricao: "Outros apps", sigla: "OT", integracao: true },
    { key: 'DELIVERY_DIRETO', descricao: "Delivery Direto - V2", sigla: "D2", integracao: true },
    { key: 'CARDAPIO_WEB', descricao: "Cardapio Web", sigla: "CW", integracao: true },
    { key: 'MULTIPEDIDOS', descricao: "Multipedidos", sigla: "MP", integracao: true },
    /* LOGISTICAS */
    { key: 'FOODYDELIVERY', descricao: "FoodyDelivery", integracao: true, sigla: "FD", logistica: true },
    { key: 'IFOODDELIVERY', descricao: "iFood Logística", integracao: false, sigla: "ID", logistica: true },
]

export const getTipoImportacaoBySigla = (sigla) => {
    let foundedType = null
    TIPOS_IMPORTACOES.map(tipoImportacao => {
        if (tipoImportacao.sigla === sigla) {
            foundedType = tipoImportacao;
        }
        return null;
    })
    return foundedType;
}

export const getSituacaoBySigla = (sigla) => {
    let foundedSituacao = null
    SITUACOES.map(situacao => {
        if (situacao.key === sigla) {
            foundedSituacao = situacao;
        }
        return null;
    })
    return foundedSituacao;
}

export const TIPOS_PEDIDOS = [
    { key: 'DELIVERY', descricao: "Delivery", sigla: "DELIVERY", icon: iconeDelivery, iconComponent: TwoWheelerIcon, checked: false },
    { key: 'BALCAO', descricao: "Balcão", sigla: "BALCAO", icon: iconeBalcao, iconComponent: RoomServiceIcon, checked: false },
    { key: 'COMANDA', descricao: "Comanda", sigla: "COMANDA", icon: iconeComanda, iconComponent: DiningIcon, checked: false },
    { key: 'MESA', descricao: "Mesa", sigla: "MESA", icon: iconeComanda, iconComponent: TableRestaurantIcon, checked: false },
    { key: 'CAIXA_RAPIDO', descricao: "Venda Rápida", sigla: "CAIXA_RAPIDO", icon: iconeVendaRapida, iconComponent: BoltOutlinedIcon, checked: false },
]

export const getTypeOrderBykey = (key) => {
    let tipo = TIPOS_PEDIDOS.find(type => (type.key === key));
    if (tipo) {
        return tipo;
    }
    return {
        key: key,
        descricao: "Não encontrado tipo para " + key,
        sigla: key,
        icon: iconeComanda
    }
}

export const getDescricaoLocalNotificacaoBySigla = (sigla) => {
    if ("NN" === sigla) {
        return "NÃO NOTIFICAR";
    }

    if ("NC" === sigla) {
        return "BAR";
    }

    if ("NZ" === sigla) {
        return "COZINHA";
    }

    if ("NN" === sigla) {
        return "NÃO NOTIFICAR";
    }

    if (sigla === undefined) {
        return " -- não configurado -- ";
    }

    return sigla;
}

const LIST_TYPES = SITUACOES

class Card {
    data;
    order;
    visible;
    shouldUpdate = function Object() { };

    get id() {
        return this.data.id
    }
    get title() {
        return this.data.iFoodOrder ? `${this.data.iFoodOrder?.customer?.name}` : "";
    }
    get subTitle() {
        return `${dateFormat(this.data?.dataPedido)}`;
    }

    get deliveryDateTo() {
        let preparationTimeInSeconds = this.data?.iFoodOrder?.preparationTimeInSeconds || 60 * 60;
        return dateFormat(addSeconds(this.data?.iFoodOrder?.createdAt, preparationTimeInSeconds), 'HH:mm');
    }

    dateFormat(date, format) {
        return dateFormat(date, format)
    }
}

class List {
    id;
    order;
    title = "";
    cards = []
}

class Board {
    title = ""
    lists = []
}



export const convertToBoard = (pedidos) => {
    let board = new Board();

    LIST_TYPES.map((listType, index) => {
        const list = new List()
        list.id = listType.key
        list.title = listType.name
        list.order = index
        board.lists.push(list)

        const pedidosFiltredByType = pedidos.filter(pedido => pedido.situacao === listType.key);
        pedidosFiltredByType.map((pedido, index) => {

            let card = createCardFromPedido(pedido)
            list.cards.push(card)
            return null;
        })
        return null;
    })

    return board
}

export const createCardFromPedido = (pedido) => {
    let card = new Card()
    card.data = pedido
    card.skeleton = pedido.skeleton
    card.visible = true
    return card
}


export const createLoadingCards = () => {
    let pedidos = []
    LIST_TYPES.map(type => {
        let pedido = { situacao: type.key }
        pedido.skeleton = true
        pedido.id = type.key
        pedidos.push(pedido)
        return null;
    })

    let board = convertToBoard(pedidos)
    return board;
}


export const changePedidoOnServer = (pedido, targetCardIndex, targetListIndex, draggedIndex) => {
    let situacao = LIST_TYPES[targetListIndex].key
    return changeSituacaoPedidoOnServer(pedido, situacao)
}


export const changeSituacaoPedidoOnServer = (pedido, situacao, motivoCancelamento, cancellationCode) => {
    return new Promise((resolve, reject) => {
        const id = pedido.id
        const oldSituation = pedido.situacao
        pedido.situacao = situacao
        let query = motivoCancelamento ? ('motivoCancelamento=' + motivoCancelamento) : ''
        query = (query.length > 0 ? query + '&' : '') + (cancellationCode ? 'cancellationCode=' + cancellationCode : '')

        const service = new AuthService()
        service.post(`/api-v2/pedidoIntegracao/situacao/${id}/${situacao}${query.length > 0 ? ('?' + query) : ''}`)
            .then(pedidoResp => {
                resolve(pedidoResp)
            }).catch(err => {
                pedido.situacao = oldSituation
                reject(err)
            })
    });
}

export const changeSituacaoMultiplosPedidosOnServer = (ids, situacao, idDeliveryMan) => {
    return new Promise((resolve, reject) => {
        let query = ''
        const service = new AuthService()

        if (idDeliveryMan) {
            query += `?idDeliveryMan=${idDeliveryMan}`
        }

        service.post(`/api-v2/pedidoIntegracao/situacao/multiple/${ids}/${situacao}${query}`)
            .then(pedidoResp => {
                resolve(pedidoResp)
            }).catch(err => {
                reject(err)
            })
    });
}

export const sendPedidoToPrint = (pedido, localImpressao) => {
    return new Promise((resolve, reject) => {
        let id = pedido.id
        let origem = pedido.origemImpressao || "NAO INFORMADO";
        let service = new AuthService()

        let queryParams = localImpressao ? `?localImpressao=${localImpressao}&origem=${origem}` : `?origem=${origem}`;
        service.post(`/api-v2/impressaoAPI/enviarImpressaoApp/PedidoIntegracao/${id}${queryParams}`)
            .then(pedidoPrintResp => {
                resolve(pedidoPrintResp)
            }).catch(err => {
                reject(err)
            })
    });
}

export const sendTefToPrint = ({ localImpressao, pedidoId, intencaoVendaId, comprovante }) => {
    return new Promise((resolve, reject) => {
        const service = new AuthService()

        service.post(`/api-v2/impressaoAPI/enviarImpressaoTef/${localImpressao}`, {
            pedidoId,
            intencaoVendaId,
            comprovante,
        }).then(resp => {
            resolve(resp)
        }).catch(err => {
            reject(err)
        })
    });
}

export const sendNotaFiscalToPrint = (idNotaFiscal, localImpressao) => {
    return new Promise((resolve, reject) => {
        let id = idNotaFiscal
        let service = new AuthService()

        let queryParams = localImpressao ? `?localImpressao=${localImpressao}` : "";
        service.post(`/api-v2/impressaoAPI/enviarImpressaoApp/NotaFiscal/${id}${queryParams}`)
            .then(pedidoPrintResp => {
                resolve(pedidoPrintResp)
            }).catch(err => {
                reject(err)
            })
    });
}

export const sendPedidoToNotificationArea = (pedido, logs) => {
    let addLog = !!logs ? `?logs=${logs}` : ""
    return new Promise((resolve, reject) => {
        let service = new AuthService()
        service.get(`/api-v2/notificacao/notificarProducao/${pedido.id}` + addLog)
            .then(respOrders => {
                resolve(respOrders)
            }).catch(err => {
                reject(err)
            })

    });
}


export const showMsgErrorOuSuccessOnReturPrint = (printResultArray = [], enqueueSnackbar) => {
    let impressoesOk = 0;
    let impressoesComErro = 0;
    let errorMsg = ""

    printResultArray.map(printResult => {
        if ("FAILURE" === printResult.result) {
            impressoesComErro += 1;
            errorMsg += printResult.message + "; "
        } else {
            impressoesOk += 1;
        }
        return null;
    })

    if (impressoesComErro > 0 && enqueueSnackbar) {
        enqueueSnackbar(`Erro ao enviar ${impressoesComErro} pedido(s) para impressao. Detalhe: ${errorMsg}`, { variant: "error" });
    }
    if (impressoesOk > 0 && enqueueSnackbar) {
        enqueueSnackbar(`${impressoesOk} pedido(s) enviado(s) com sucesso para impressão`, { variant: 'info' });

    }
}

export const convertBoarToPedidoList = (board) => {
    const orders = []

    board.lists.map(list => {
        list.cards.map(card => {
            if (card) {
                if (!card.skeleton) {
                    orders.push(card.data)
                }
            }
            return null;
        })
        return null;
    })

    return orders
}

export const sortCardBoard = (board) => {

    board.lists.map(list => {
        let cards = list.cards;

        //console.log("NAO ORDENADO", cards)
        let sortedCards = cards.sort((cardB, cardA) => new Date(cardA.data.dataPedido) - new Date(cardB.data.dataPedido));
        list.cards = sortedCards;
        //console.log("ORDENADO", sortedCards)
        return null;
    })

    return board;
}

export const useHasAguardandoRetirada = () => {
    const generalParameters = useSelector(state => state.generalParameters)
    const COLUNA_AGUARDANDO_RETIRADA_PDV = getParamByKey(generalParameters, 'COLUNA_AGUARDANDO_RETIRADA_PDV')?.valor

    return COLUNA_AGUARDANDO_RETIRADA_PDV === 1
}