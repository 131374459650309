import React from 'react'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Dialog, Hidden } from '@mui/material'
import { DialogActions, DialogContent, DialogTitle } from '../../../pages/layout-components/CustomDialog/dialogComponents'

export default function DefaultDialogLayout({
    open,
    children,
    onClose,
    onCancel,
    onConfirm,
    maxWidth = 'xs',
    disableClose = false,
    showCancelButton = true,
    cancelLabel = 'Cancelar',
    confirmLabel = 'Confirmar',
}) {
    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={maxWidth}
            onClose={!disableClose ? () => onCancel() : undefined}
            onTransitionExited={!disableClose ? () => onClose() : undefined}>

            <DialogTitle onClose={!disableClose ? () => onCancel() : undefined}>
                <Box display="flex" justifyContent="center">
                    <ErrorOutlineIcon fontSize="large" color="warning" />
                </Box>
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                <Hidden smUp>
                    <Box width="100%">
                        {showCancelButton && !disableClose ? <Button fullWidth onClick={onCancel} size='small'>{cancelLabel}</Button> : null}
                        <Button fullWidth onClick={onConfirm} variant="contained" size='small'>{confirmLabel}</Button>
                    </Box>
                </Hidden>
                <Hidden smDown>
                    {showCancelButton && !disableClose ? <Button onClick={onCancel} size='small'>{cancelLabel}</Button> : null}
                    <Button onClick={onConfirm} size='small' variant="contained">{confirmLabel}</Button>
                </Hidden>
            </DialogActions>

        </Dialog>
    )
}