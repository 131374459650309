export const toogleDialogSelectWaiter = (currentState, action) => {
    let { dialogSelectWaiter }= action
  
    return {
      ...currentState,
      dialogSelectWaiter: { ...dialogSelectWaiter, usuario: dialogSelectWaiter.usuarios, open: dialogSelectWaiter.open }
    }
  }
  
  export const initialDialogSelectWaiter = () => {
    return {
      open: false,
      usuario: null,
    }
  }